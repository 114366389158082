const transEN = {
    'privacyPara1': 'Easyship built the Easyship app as a Free app. This SERVICE is provided by Easyship at no cost and is intended for use as is.',
    'privacyPara2': 'This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.',
    'privacyPara3': 'If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.',
    'privacyPara4': 'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Easyship unless otherwise defined in this Privacy Policy.',
    'privacyHeading2': 'Information Collection and Use',
    'privacyPara5': 'For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to NA. The information that we request will be retained by us and used as described in this privacy policy.',
    'privacyHeading3': 'Log Data',
    'privacyPara6': 'We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.',
    'privacyHeading4': 'Cookies',
    'privacyPara7': 'Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device\'s internal memory.',
    'privacyPara8': 'This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.',
    'privacyHeading5': 'Service Providers',
    'privacyPara9': 'We may employ third-party companies and individuals due to the following reasons:',
    'To facilitate our Service': 'To facilitate our Service',
    'To provide the Service on our behalf': 'To provide the Service on our behalf',
    'To perform Service-related services': 'To perform Service-related services; or',
    'To assist us in analyzing how our Service is used.': 'To assist us in analyzing how our Service is used.',
    'privacyPara10': 'We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.',
    'privacyHeading6': 'Security',
    'privacyPara11': 'We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.',
    'privacyHeading7': 'Links to Other Sites',
    'privacyPara12': 'This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.',
    'privacyHeading8': 'Children’s Privacy',
    'privacyPara13': 'These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.',

    'privacyHeading9': 'Changes to This Privacy Policy',

    'privacyPara14': 'We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.',
    'privacyPara15': 'This policy is effective as of 2020-11-18',
    'privacyHeading10': 'Contact Us',
    'privacyPara16': 'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at',
    'aboutUsPara': 'A fast and reliable International Express Shipping Service. Sophisticated tools as real-time shipment Tracking, Transit Times and Customs Tools with International Document preparation. Signature Proof of delivery on every Shipment you send and receive. Effective and fast Customs clearance.'


}

const transFR = {
    'privacyPara1': 'Easyship a construit l\'application Easyship en tant qu\'application gratuite. Ce SERVICE est fourni par Easyship sans frais et est destiné à être utilisé tel quel.',
    'privacyPara2': 'Cette page est utilisée pour informer les visiteurs de nos politiques de collecte, d\'utilisation et de divulgation de renseignements personnels si quelqu\'un décide d\'utiliser notre service.',
    'privacyPara3': 'Si vous choisissez d\'utiliser notre service, vous acceptez la collecte et l\'utilisation des informations relatives à cette politique. Les informations personnelles que nous collectons sont utilisées pour fournir et améliorer le service. Nous n\'utiliserons ni ne partagerons vos informations avec qui que ce soit, sauf comme décrit dans cette politique de confidentialité.',
    'privacyPara4': 'Les termes utilisés dans cette politique de confidentialité ont la même signification que dans nos conditions générales, qui sont accessibles sur Easyship, sauf indication contraire dans la présente politique de confidentialité.',
    'privacyHeading2': 'Collecte et utilisation des informations',
    'privacyPara5': 'Pour une meilleure expérience, lors de l\'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables, y compris, mais sans s\'y limiter, NA. Les informations que nous demandons seront conservées par nous et utilisées comme décrit dans cette politique de confidentialité.',
    'privacyHeading3': 'Données du journal',
    'privacyPara6': 'Nous tenons à vous informer que chaque fois que vous utilisez notre service, en cas d\'erreur dans l\'application, nous collectons des données et des informations (via des produits tiers) sur votre téléphone appelées données de journal. Ces données de journal peuvent inclure des informations telles que l\'adresse IP (Internet Protocol) de votre appareil, le nom de l\'appareil, la version du système d\'exploitation, la configuration de l\'application lors de l\'utilisation de notre service, l\'heure et la date de votre utilisation du service et d\'autres statistiques.',
    'privacyHeading4': 'Cookies',
    'privacyPara7': 'Les cookies sont des fichiers contenant une petite quantité de données qui sont couramment utilisés comme identifiants uniques anonymes. Ceux-ci sont envoyés à votre navigateur à partir des sites Web que vous visitez et sont stockés dans la mémoire interne de votre appareil.',
    'privacyPara8': 'Ce service n\'utilise pas ces "cookies" de manière explicite. Cependant, l\'application peut utiliser du code tiers et des bibliothèques qui utilisent des «cookies» pour collecter des informations et améliorer leurs services. Vous avez la possibilité d\'accepter ou de refuser ces cookies et de savoir quand un cookie est envoyé à votre appareil. Si vous choisissez de refuser nos cookies, vous ne pourrez peut-être pas utiliser certaines parties de ce service.',
    'privacyHeading5': 'Les fournisseurs de services',
    'privacyPara9': 'Nous pouvons employer des sociétés tierces et des particuliers pour les raisons suivantes:',
    'To facilitate our Service': 'Pour faciliter notre service',
    'To provide the Service on our behalf': 'Pour fournir le service en notre nom',
    'To perform Service-related services': 'Pour exécuter des services liés au service; ou',
    'To assist us in analyzing how our Service is used.': 'Pour nous aider à analyser la manière dont notre service est utilisé.',
    'privacyPara10': 'Nous souhaitons informer les utilisateurs de ce service que ces tiers ont accès à vos informations personnelles. La raison est d\'exécuter les tâches qui leur sont confiées en notre nom. Cependant, ils sont tenus de ne pas divulguer ni utiliser les informations à d\'autres fins.',

    'privacyHeading6': 'Sécurité',
    'privacyPara11': 'Nous apprécions votre confiance en nous fournissant vos informations personnelles, c\'est pourquoi nous nous efforçons d\'utiliser des moyens commercialement acceptables pour les protéger. Mais rappelez-vous qu\'aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique n\'est 100% sûre et fiable, et nous ne pouvons garantir sa sécurité absolue.',
    'privacyHeading7': 'Liens vers d\'autres sites',
    'privacyPara12': 'Ce service peut contenir des liens vers d\'autres sites. Si vous cliquez sur un lien tiers, vous serez dirigé vers ce site. Notez que ces sites externes ne sont pas exploités par nous. Par conséquent, nous vous conseillons vivement de consulter la politique de confidentialité de ces sites Web. Nous n\'avons aucun contrôle et n\'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers.',
    'privacyHeading8': 'La vie privée des enfants',
    'privacyPara13': 'Ces Services ne s\'adressent à personne de moins de 13 ans. Nous ne collectons pas sciemment d\'informations personnellement identifiables d\'enfants de moins de 13 ans. Dans le cas où nous découvrons qu\'un enfant de moins de 13 ans nous a fourni des informations personnelles, nous les supprimons immédiatement de nos serveurs. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des informations personnelles, veuillez nous contacter afin que nous puissions faire les actions nécessaires.',

    'privacyHeading9': 'Modifications de cette politique de confidentialité',

    'privacyPara14': 'Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Ainsi, il vous est conseillé de consulter cette page périodiquement pour tout changement. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.',
    'privacyPara15': 'Cette politique est en vigueur à compter du 2020-11-18',
    'privacyHeading10': 'Contact Us',
    'privacyPara16': 'Si vous avez des questions ou des suggestions concernant notre politique de confidentialité, n\'hésitez pas à nous contacter à',
    'aboutUsPara': 'Un service d\'expédition express international rapide et fiable. Des outils sophistiqués tels que le suivi des expéditions en temps réel, les temps de transit et les outils de douane avec préparation de documents internationaux Signature Preuve de livraison sur chaque Envoi que vous envoyez et recevez. Dédouanement efficace et rapide.'

}

export default {
    en: transEN,
    fr: transFR
}
