const transEN = {

    'dhlHeading1': 'Sending parcels or pallets with DHL',
    'dhlHeading2': 'DHL is one of the world leaders in <3> air express transport </3> and is\n' +
    '                            recognized\n' +
    '                            for its reliability. It allows companies and individuals to send\n' +
    '                            <3> documents, parcels or\n' +
    '                            pallets </3> thanks to its express offers DHL delivers in 220 countries',
    'dhlPara1': 'We have pooled the shipments of thousands of VSE / SME companies as well as large groups in order to obtain  <3> negotiated rates with DHL </3> to allow you to <3> send your parcels at a lower cost </3>',
    'dhlHeading3': 'DHL Express',
    'dhlPara2': 'available for sending documents, parcels and pallets',
    'dhlPara3': 'home collection and delivery',
    'dhlPara4': 'delivery between 1 and 5 days (depending on the destination)',
    'dhlPara5': 'maximum 300 kg per shipment'
}

const transFR = {
    'dhlHeading1': 'L\'envoi de colis ou palette avec DHL',
    'dhlHeading2': 'DHL fait partie des leaders mondiaux du <3>transport express aérien</3> et est\n' +
    '                            reconnue\n' +
    '                            pour sa fiabilité. Elle permet aux entreprises comme aux particuliers d’envoyer\n' +
    '                            des<3> plis, colis ou\n' +
    '                            palettes</3> grâce à ses offres express DHL livre dans 220 pays',
    'dhlPara1': 'Nous avons mutualisé les envois de milliers d\'entreprises TPE/PME mais aussi de grands\n' +
    '                            groupes afin\n' +
    '                            d\'obtenir des <3>tarifs négociés avec DHL </3>pour vous permettre d\'<3>envoyer\n' +
    '                                vos colis\n' +
    '                                moins cher.</3>',
    'dhlHeading3': 'DHL Express',
    'dhlPara2': 'disponible pour l\'envoi de plis, colis et palettes',
    'dhlPara3': 'enlèvement et livraison à domicile',
    'dhlPara4': 'livraison entre 1 et 5 jours (suivant la destination)',
    'dhlPara5': 'maximum 300 kg par expédition'
}

export default {
    en: transEN,
    fr: transFR
}
