const transEN = {
    'GLOBAL SHIPPING SPECIALISTS': 'EXPRESS SHIPPING SPECIALISTS IN MOROCCO',
    'Our Services': 'Our Services',
    'Our Expertise': 'Our Expertise',
    'Business Offer': 'Business Offer',
    'Blog': 'Blog',
    'Help': 'Help',
    'Login': 'Login',
    'Logout': 'Logout',
    'Create My Account': 'Create My Account',
    'Proudly Shipping in Collaboration with': 'Proudly Shipping in Collaboration with',
    'Package Type': 'Package Type',
    'Parcel': 'Parcel',
    //  'Envelope': 'Fold',
    'Envelope': 'Document',

    'Pallet': 'Palette',
    'Desired Pickup Date': 'Desired Pickup Date',
    'Select date': 'Select date',
    'INBOUND': 'Import',
    'OUTBOUND': 'Export',
    'Pickup Location': 'Pickup Location',
    'Package Destination': 'Package Destination',
    'Zip Code': 'Zip Code',
    'cityname,zipcode': 'cityname,zipcode',
    'Details': 'Details',
    'Parcel Details': 'Parcel Details',
    'Dimensions': 'Dimensions',
    'Qty': 'Quantity',
    'weight/unit': 'weight/unit',
    'Length': 'Length',
    'Width': 'Width',
    'Height': 'Height',
    'Add a Package': 'Add a Package',
    'Compare Prices': 'Get Rates',
    'Compare & Send': 'Compare & Send Packages at Cheaper Price!',
    'rightMsg': 'Questions? <1/> Send WhatsApp message on',
    'rightMsgNo': '07 01 01 02 02',
    'leading1': 'Easyship enjoys special partnerships with Express Shipping Carriers such as DHL, UPS, you may now enjoy the services of the leaders in international express delivery while reducing your costs! Do not wait to try Easyship, in a few clicks, order your express delivery on our site.',
    'leading2': 'According to your choice, Easyship, DHL or UPS will collect your shipment, an envelope, a bulky parcel, or a pallet and will deliver it to your recipient. Easyship ensures the delivery of your shipment all the way to your destination, with full transparency since at any given moment, you can track your parcel',
    'leading3': 'Easyship Support Center professionals are happy to provide feedback at any given time.',
    'leadingArrow': 'Easyship, the best way to ship your parcel in Morocco',
    'One Stop Shipping Solution': 'Ship with Easyship and save your time and money !',
    // 'Start Shipping Today': 'Start Shipping Today',
    'Ship a Document to France': 'Ship a Document to France',
    'Ship a Document to US': 'Ship a Document to US',
    'Ship a Document to Spain': 'Ship a Document to Spain',
    'priceShipping': 'Starting at <1>300 MAD</1>',
    'priceShipping2': 'Starting at <1>450 MAD</1>',
    'priceShipping3': 'Starting at <1>300 MAD</1>',
    'Send Now': 'Send Now',

    'quickStepsheading': 'Send your package in 5 simple and quick steps!',
    'quickStepsline1': 'Are you looking to save time, money and hassle for your shipment? Trust Easyship technology. Thanks to its innovative platform, easily manage all your logistics activity in one place.',
    'quickStepsline2': 'Follow the Instructions for using the Easyship price comparator for sending your parcels.',
    'Step': 'Step',
    'step1SubText1': 'To ship your document, package or pallet :',
    'step1SubText2': 'Fill in the requested information on the',
    'step1SubText3': 'form',
    'step1SubText4': 'above.',
    'step1SubText5': 'Click on <1>"Compare prices"</1> and access the offers of the Carriers.',

    'step2Text': 'After accessing the carriers\' quotes :',
    'step2SubText1': 'Compare prices, Transit Time',
    'step2SubText2': 'Select the carrier of your choice',
    'step3Text': 'It\'s time to fill out your packing slip :',
    'step3SubText1': 'Fill in the sender and recipient information',
    'step3SubText2': 'Enter the nature of your shipment',
    'step3SubText3': 'Indicate the desired Pickup Schedule',
    'step3SubText4': 'Select the desired insurance',
    'step3SubText5': 'Accept the General Conditions of Sale',
    'step4Text': 'Almost finished...',
    'step4SubText1': 'Check your shipment information',
    'step4SubText2': 'Confirm the order',
    'step4SubText3': 'Choose the desired payment method',
    'step5Text': 'There you go !',
    'step5SubText1': 'Print your transport documents on the portal and sent by email.',
    'step5SubText2': 'Stick the slip on your package',
    'step5SubText3': 'Wait for the agent to arrive or drop off your package yourself.',
    'cargoEasyshipPro': 'Easyship Pro',
    'cargoHeading': 'Is Express shipping out of Budget?',
    'cargoSubheading': 'No problem, Easyship has many partnerships with Airfreight, Ground Transportation and Ocean Fright Companies.',
    'cargoSubheading1': 'Let us get the best rates and ensure a successful tracking to your shipment.',
    'contactHeading': 'Contact Us :',
    'contactSubheading': 'Info@easyship.ma',
    'About': 'About',
    'What is Easyship?': 'What is Easyship',
    'Contact Us': 'Contact Us',
    'Jobs': 'Jobs',
    'Quantity': 'Quantity',
    'Edit': 'Edit',
    'Select Offer': 'Select Offer',
    'Shipping': 'Ship',

    // 'Select Country': 'Select Country',
    'Verification': 'Verify',
    'Pay': 'Pay',
    'Done': 'Easy',
    'Create your account in 3 minutes!': 'Create your account in 3 minutes!',
    'You are a professional?': 'You are a professional? Open a business account to benefit from our best negociated\n' +
    '                                rates and shipping tools designed for your everyday needs',
    'Password': 'Password',
    'Please input your password!': 'Please input your password!',
    'Confirm Password': 'Confirm Password',
    'I am a professional': 'I am a professional',
    'Please input your confirm password!': 'Please input your confirm password!',
    'Open my account': 'Open my account',
    'Incorrect login/password. Please try again': 'Incorrect login/password. Please try again',
    'Forgot Password': 'Forgot Password',
    'You do not have an account?': 'You do not have an account?',
    'Request a quote': 'Request a quote',
    'Sponsor a professional': 'Sponsor a professional',
    'Create my account': 'Create my account',
    'Practical Information': 'Practical Information',
    'Prepare your shipment': 'Prepare your shipment',
    'Prohibited goods': 'Prohibited goods',
    'Customs Obligations': 'Customs Obligations',
    'E-Commerce connectors': 'E-Commerce Plug & Play',
    'GC of DHL': 'GC of DHL',
    'GC of UPS': 'GC of UPS',
    'GC of Fedex': 'GC of Fedex',
    'GC of Easyship': 'GC of Easyship',
    'Business Information': 'Business Information',
    'Privacy Policy': 'Privacy Policy',
    'Legal notices': 'Legal notices',
    'Cookies policy': 'Cookies policy',
    'Copyright': 'Copyright',
    'Easyship': 'Easyship',
    'All Rights Reserved': 'All Rights Reserved',
    'Open a Pro Account': 'Open Easyship PRO Account',
    'Send us a Referral': 'Send us a Referral',
    'Request Rates': 'Request Rates',
    'Please complete': 'Please complete all required fields to complete your registration',
    'Society': 'Society',
    'City': 'City',
    'Country': 'Country',
    'Postal Code': 'Postal Code',
    'Address': 'Address',
    'No,Street,Complement': 'No,Street,Complement',
    'Email for receipt of invoices': 'Email for receipt of invoices',
    'Use another address for invoicing': 'Use another address for invoicing',
    'I have read the terms and conditions of use of the easyship.ma website and accept all the terms.': 'I have read the terms and conditions of use of the easyship.ma website and accept all the terms.',
    'I would like to receive newsletters and promotional offers from Easyship': 'I would like to receive newsletters and promotional offers from Easyship',
    'Email already exists': 'Email already exists',
    'User added Successfully': 'User added Successfully',
    'Profile Updated Successfully': 'Profile Updated Successfully',
    'Error Getting Data': 'Error Getting Data',
    'Update Profile': 'Update Profile',
    'Address Book': 'Address Book',
    'My Quotes': 'My Quotes',
    'Edit Quote': 'Edit Quote',
    'Why use Easyship ?': 'Why use Easyship',
    'Coupons and Promotions': 'Coupons and Promotions',
    'with tax': 'with tax',
    'with insurance': 'with insurance',
    'Contacted successfully!': 'Contacted successfully!',
    'Registration error!': 'Registration error!',
    'Shipment Label': 'Shipment Label',
    'Download Label': 'Download Label',
    'Payment Id': 'Payment Id',
    'Pickup Ref No': 'Pickup Ref No',
    'Payment Successful': 'Payment Successful',
    'Order Status': 'Order Status',
    'Amount Paid': 'Amount Paid',
    'OOPS Something went wrong': 'OOPS Something went wrong',
    'Back To Home': 'Back To Home',
    'Try Again': 'Try Again',
    'Download Commercial': 'Download Commercial',
    'Total Amount': 'Total Amount',
    'Shipment Response': 'Shipment Response',
    'Payment Response': 'Payment Response',
    'View History': 'View History',
    'Action': 'Action',
    'Transaction Id': 'Transaction Id',
    'Name': 'Name',
    'Response': 'Response',
    'Payment Type': 'Payment Type',
    'Amount': 'Amount',
    'Shipment List': 'Confirmed Shipment List',
    'Payment List': 'Payment List',
    'Quote List': 'Quote List',
    'View Shipment': 'View Shipment',
    'View Quote': 'View Quote',
    'My Payments': 'My Payments',
    'Shipment History': 'Shipment History',
    'Packages': 'Packages',
    'Receiver Address': 'Receiver Address',
    'Sender Address': 'Sender Address',
    'Shipment (Response)': 'Shipment (Response)',
    'Payment (Response)': 'Payment (Response)',
    'Pick Up Number': 'Pick Up Number',
    'Pick Date': 'Pick Date',
    'From Time': 'From Time',
    'To': 'To',
    'Message': 'Message',
    'Vat Charges': 'Vat Charges',
    'Fuel Charges': 'Fuel Charges',
    'Reschedule Pickup': 'Reschedule Pickup',
    'Schedule Pickup': 'Schedule Pickup',
    'Track another Shipment': 'Track another Shipment',
    'From': 'From',
    'Pieces': 'Pieces',
    'Payment Methods': 'Payment Methods',
    'Are you sure Void Shipment': 'Are you sure Void Shipment',
    'Void Shipment': 'Void Shipment',
    'Shipment Tracking': 'Shipment Tracking',
    'Pending Shipment List': 'Progress Shipment List',
    'Pending Shipments': 'Shipments in Progress',
    'Cancel Shipment List': 'Cancel Shipment List',
    'Cancel Shipments': 'Canceled Shipments',
    'Placed Shipments': 'Confirmed Shipments',
    'Payment Details': 'Payment Details',
    'Mode of Payment': 'Mode of Payment',
    'Shipments': 'My Shipments',
    'Your Payment Is Pending': 'Your Payment Is Pending',
    'Insurance Amount': 'Insurance Amount',
    'PAYMENT DETAILS': 'PAYMENT DETAILS',
    'Payment Mode': 'Payment Mode',
    'Payment Date': 'Payment Date',
    'Notes': 'Notes',
    'Remarks': 'Remarks',
    'Reference Number': 'Reference Number',
    'Chèque': 'Cheque',
    'Espèces': 'Cash',
    'Virement / Versement Bancaire': 'Bank transfer / deposit',
    'Pending Amount': 'Pending Amount',
    'Created At': 'Created At',
    'Updated At': 'Updated On',
    'Payment Pending': 'Payment Pending',
    'notRegister': 'not registered with us',
    'We have sent a Otp to': 'We have sent a Otp to',
    'Invalid OTP': 'Invalid OTP',
    'Password changed successfully.': 'Password changed successfully.',
    'Change Password': 'Change Password',
    'Please Select One Payment Method': 'Please Select One Payment Method',
    'Total Amount With Tax': 'Total Amount With Tax',
    'Net Amount': 'Net Amount',
    'Coupon Discount': 'Coupon Discount',
    'Payable Amount': 'Payable Amount',
    'DomesticType': 'Domestic',
    'Send a Whatsapp': 'Send us a WhatsApp message',
    'About Us': 'About Us',
    'Download Easyship Mobile App': 'Download Easyship Mobile App'


}

const transFR = {
    'GLOBAL SHIPPING SPECIALISTS': 'SPÉCIALISTE DU TRANSPORT EXPRESS AU MAROC',
    'Create My Account': 'Créer mon compte',
    'Our Services': 'Nos Services',
    'Our Expertise': 'Notre Expertise',
    'OUTBOUND': 'Export',
    'INBOUND': 'Import',
    'Package Type': 'Type D\'emballage',
    'Parcel': 'Collis',
    'Envelope': 'Document',

    // 'Envelope': 'Pli',
    'Pallet': 'Palette',
    'Desired Pickup Date': 'Date d\'envoi',
    'Select date': 'Sélectionner une date',
    'Pickup Location': 'Départ',
    'Package Destination': 'Arrivée',
    'Zip Code': 'Code postal',
    'cityname,zipcode': 'ville,code postal',
    'Parcel Details': 'Détails Du Colis',
    'Dimensions': 'Dimensions',
    'Country': 'Pays',
    'Qty': 'Qty',
    'weight/unit': 'Poids / Unité',
    'Length': 'Long',
    'Width': 'Larg',
    'Height': 'Haut',
    'Add a Package': 'Ajouter un colis',
    'Compare Prices': 'Meilleures Offres',
    'Compare & Send': 'Comparez et envoyez vos documents et colis à un prix moins cher!',
    'rightMsg': 'Questions? <1/> Envoyez un message WhatsApp sur',
    'rightMsgNo': '07 01 01 02 02',
    'Proudly Shipping in Collaboration with': 'Proudly Shipping in Collaboration with',
    'leading1': '',
    'leading2': 'Grâce aux partenariats et tarifs exclusifs de Easyship avec DHL, UPS, offrez vous les services des leaders du transport international tout en réduisant vos coûts ! N\'attendez plus pour découvrir Easyship, en quelques clics seulement, commandez votre transport express sur notre site qu\'il s\'agisse d\'un pli, d\'un colis volumineux ou d\'une palette et le livre à votre destinataire via DHL, UPS. Easyship assure la livraison de votre expéditions à destination ou en provenance de 220 pays, en toute transparence puisqu\'à tout moment, vous pouvez suivre votre colis.',
    'leading3': 'Le Centre de Support Easyship est à votre écoute 7 jours sur 7.',
    'leadingArrow': 'Easyship la meilleure façon d’envoyer ou recevoir un colis au Maroc !',
    'One Stop Shipping Solution': 'Expédiez avec Easyship et économisez votre temps et votre argent !',
    //'Start Shipping Today': 'VOTRE TEMPS ET VOTRE ARGENT !',
    'Ship a Document to France': 'Envoi de document en France',
    'Ship a Document to US': 'Envoi de Colis Aux Etats Unis',
    'Ship a Document to Spain': 'Envoi de Document en Espagne',
    'priceShipping': 'À partir de <1> 300 MAD </1>',
    'priceShipping2': 'À partir de <1> 450 MAD </1>',
    'priceShipping3': 'À partir de <1> 300 MAD </1>',
    'Send Now': 'Envoyer',
    'quickStepsheading': 'Envoyez votre colis en 5 étapes simples et rapides!',
    'quickStepsline1': 'Vous souhaitez économiser du temps, de l\'argent et des tracas pour votre envoi? Faites confiance à la technologie Easyship. Grâce à sa plateforme innovante, gérez facilement toutes vos activités logistiques en un seul endroit.',
    'quickStepsline2': 'Suivez les instructions d\'utilisation du comparateur de tarifs Easyship pour l\'envoi de vos colis.',
    'Step': 'Étape',
    'step1SubText1': 'Pour expédier votre document, colis ou palette :',
    'step1SubText2': 'Renseignez les informations demandées sur le',
    'step1SubText3': 'formulaire',
    'step1SubText4': 'ci-dessus.',
    'step1SubText5': 'Cliquez sur <1>« Comparer les prix »</1> et accéder aux offres des Transporteurs.',
    'step2Text': 'Après avoir accéder aux devis des transporteurs :',
    'step2SubText1': 'Comparez les tarifs, Transit Time',
    'step2SubText2': ' Sélectionnez le transporteur de votre choix',
    'step3Text': 'Il est temps de remplir votre bordereau d’expédition :',
    'step3SubText1': 'Remplissez les informations de l’expéditeur et destinataire',
    'step3SubText2': 'Renseigner la nature de votre envoi',
    'step3SubText3': 'Indiquez la tranche Horaire de ramassage souhaitée',
    'step3SubText4': 'Sélectionnez l’assurance souhaitée',
    'step3SubText5': 'Acceptez les Conditions Générales de Vente',
    'step4Text': 'Presque fini...',
    'step4SubText1': 'Vérifier les informations de votre envoi',
    'step4SubText2': 'Confirmez la commande',
    'step4SubText3': 'Choisissez la méthode de paiement souhaitée',
    'step5Text': 'et voila !',
    'step5SubText1': 'Imprimez vos documents de transport sur le portail et envoyés par mail.',
    'step5SubText2': 'Collez le bordereau sur votre colis',
    'step5SubText3': 'Attendez l’arrivée de l’agent ou déposez votre colis vous-même.',
    'cargoEasyshipPro': '',
    'cargoSubheading1': '',
    'cargoHeading': 'Le Transport Express est hors budget?',
    'cargoSubheading': 'Pas de problème ! Easyship a également mis en place plusieurs partenariats dans le transport routier, maritime ou fret aérien.\n' +
    'Permettez nous de vous assurer la meilleure prestation au meilleur prix.',
    'contactHeading': 'Contactez nous :',
    'contactSubheading': 'Info@easyship.ma',
    'About': 'A propos',
    'What is Easyship?': 'Qui sommes nous',
    'Why use Easyship ?': 'Pourquoi Choisir Easyship',
    'Contact Us': 'Nous Contacter',
    'Jobs': 'Recrutement',
    'Help': 'Centre de Support',
    'Practical Information': 'Informations Pratiques',
    'Prepare your shipment': 'Bien préparer son envoi ',
    'Prohibited goods': 'Marchandises interdites',
    'Customs Obligations': 'Obligations Douanières',
    'GC of DHL': 'CGC de DHL',
    'GC of UPS': 'CGC de UPS',
    'GC of Fedex': 'CGC de Fedex',
    'GC of Easyship': 'CGC de Easyship',
    'Business Information': 'Informations D\'affaires',
    'Business Offer': 'Offres Pro',
    'Open a Pro Account': 'Créer un Compte Société',
    // 'Send us a Referral': 'Envoyez-nous une référence',
    'Request Rates': 'Demander les Tarifs',
    'Request a quote': 'Demander un devis',
    'Privacy Policy': 'Politique de confidentialité',
    'Legal notices': 'Mentions légales',
    'Cookies policy': 'Politique de cookies',
    'Copyright': 'Copyright',
    'Easyship': 'Easyship',
    'All Rights Reserved': 'Tous Droits Réservés',
    'Login': 'Se Connecter',
    'E-Commerce connectors': 'E-Commerce Plug & Play',
    'Coupons and Promotions': 'Coupons et Promotions',
    'Shipping': 'Expédier',
    'Verification': 'Vérifier',
    'Pay': 'Payer',
    'Done': 'Easy',
    'Quantity': 'Quantité',
    'Edit': 'Changer',
    'with tax': 'TTC',
    'with insurance': 'avec assurance',
    'Select Offer': 'Choisir l\'offre',
    'Contacted successfully!': 'Contacté avec succès!',
    'Registration error!': 'Erreur d\'enregistrement!',
    'Shipment Label': 'Étiquette d\'expédition',
    'Download Label': 'Bordereau d\'expédition\n',
    'Payment Id': 'ID de paiement',
    'Pickup Ref No': 'No de référence de ramassage',
    'Payment Successful': 'Paiement réussi',
    'Order Status': 'Statut de la commande',
    'Amount Paid': 'Montant payé',
    'Email already exists': "l'email existe déjà",
    'Please input your password!': 'Veuillez saisir votre mot de passe!',
    'Incorrect login/password. Please try again': 'Identifiant / mot de passe incorrect. Veuillez réessayer',
    'Create your account in 3 minutes!': 'Créez votre compte en 3 minutes!',
    'I have read the terms and conditions of use of the easyship.ma website and accept all the terms.': "J'ai lu les conditions générales d'utilisation du site easyship.ma et j'accepte toutes les conditions.",
    'Password': 'Mot de passe',
    'Confirm Password': 'Confirmez le mot de passe',
    'Open my account': 'Ouvrir mon compte',
    'Forgot Password': 'Mot de passe oublié',
    'Please complete': 'Veuillez remplir tous les champs obligatoires pour completer votre inscription',
    'I would like to receive newsletters and promotional offers from Easyship': "Je souhaite recevoir des newsletters et des offres promotionnelles d'Easyship",
    'Profile Updated Successfully': 'Mise à jour du profil réussie',
    'OOPS Something went wrong': "OOPS Une erreur s'est produite",
    'Back To Home': 'De retour à la maison',
    'Try Again': 'Réessayer',
    'Address Book': "Carnet d'adresses",
    'Download Commercial': 'Facture Commerciale',
    'Total Amount': 'Montant total',
    'Shipment Response': 'Réponse d\'expédition\n',
    'Payment Response': 'Réponse de paiement',
    'View History': 'Voir l\'historique',
    'Action': 'Action',
    'Transaction Id': 'Identifiant de transaction',
    'Name': 'Nom',
    'Response': 'Réponse',
    'Payment Type': 'Type de paiement',
    'Amount': 'Montante',
    'Shipment List': 'Liste d\'expédition confirmée',
    'Payment List': 'Liste de paiement',
    'Quote List': 'Liste de devis',
    'Edit Quote': 'Liste d\'édition',
    'View Shipment': 'Voir l\'expédition',
    'View Quote': 'Afficher le devis',
    'User added Successfully': 'Utilisateur ajouté avec succès',
    'My Quotes': 'Mes Devis',
    'My Payments': 'Mes Paiements',
    'Update Profile': 'Mettre profile à jour',
    'Shipment History': 'Historique des expéditions',
    'Logout': 'Se déconnecter',
    'Packages': 'Paquets',
    'Receiver Address': 'Adresse du destinataire',
    'Sender Address': 'Adresse de l\'expéditeur',
    'Shipment (Response)': 'Expédition (réponse)',
    'Payment (Response)': 'Paiement (réponse)',
    'Add Pickup': 'Planifier le ramassage',
    'Pick Up Number': 'Numéro de ramassage',
    'Pick Date': 'Choisir la date',
    'From Time': 'De temps',
    'To': 'À',
    'Message': 'Message',
    'Vat Charges': 'Frais de TVA',
    'Fuel Charges': 'Frais de carburant',
    'Reschedule Pickup': 'Reprogrammer le ramassage',
    'Schedule Pickup': 'Planifier le ramassage',
    'Track another Shipment': 'Suivre un autre envoi',
    'From': 'De',
    'Pieces': 'Pièces',
    'Payment Methods': 'Modes de Paiement',
    'Are you sure Void Shipment': 'Êtes-vous sûr de l\'annulation de l\'envoi',
    'Void Shipment': 'Envoi annulé',
    'Shipment Tracking': 'Suivi des expéditions',
    'Pending Shipment List': 'Liste des envois en cours',
    'Pending Shipments': 'Expéditions en cours',
    'Cancel Shipment List': 'Annuler la liste d\'expédition',
    'Cancel Shipments': 'Expéditions Annulées',
    'Placed Shipments': 'Expéditions Confirmées',
    'Payment Details': 'Détails de paiement',
    'Mode of Payment': 'Moyen de paiement',
    'Shipments': 'Mes Expéditions',
    'Your Payment Is Pending': 'Paiement en cours',
    'Insurance Amount': 'Montant d\'assurance',
    'PAYMENT DETAILS': 'DÉTAILS DE PAIEMENT',
    'Payment Mode': 'Mode de Paiement',
    'Payment Date': 'Date de Paiement',
    'Notes': 'Remarques',
    'Remarks': 'Remarques',
    'Reference Number': 'Numéro de réference',
    'Chèque': 'Chèque',
    'Espèces': 'Espèces',
    'Virement / Versement Bancaire': 'Virement / Versement Bancaire',
    'Pending Amount': 'Montant en cours',
    'Created At': 'Créé le',
    'Updated At': 'Mise à jour le',
    'Payment Pending': 'Paiement en cours',
    'notRegister': 'non enregistré avec nous',
    'We have sent a Otp to': 'Nous avons envoyé un Otp à',
    'Invalid OTP': 'OTP non valide',
    'Password changed successfully.': 'Le mot de passe a été changé avec succès.',
    'Change Password': 'Changer le mot de passe',
    'Please Select One Payment Method': 'Veuillez sélectionner un mode de paiement',
    'Total Amount With Tax': 'Montant total avec taxe',
    'Net Amount': 'Montant net',
    'Coupon Discount': 'Remise de Coupon',
    'Payable Amount': 'Montant payable',
    'DomesticType': 'National',
    'Send a Whatsapp': 'Envoyez nous un message WhatsApp',
    'About Us': 'À propos de nous',
    'Download Easyship Mobile App': 'Téléchargez l\'application mobile Easyship',
    'Society':'Société'

}

export default {
    en: transEN,
    fr: transFR
}
