const transEN = {
    'RequestPrices': 'Request Prices',
    'requestPriceHeading1': "Thank you for your price request. Your prices will be sent to you by email as soon as possible. Don't forget to open a free, no-obligation business account so that you can benefit from your rates and unparalleled service.",
    'companyName': 'Company Name',
    'How many shipments do you make per month': 'How many shipments do you make per month',
    'Are you an E-commerce': 'Are you an E-commerce',
    'What sector of activity': 'What sector of activity',
    'What is your current carrier': 'What is your current carrier',
    'Taux de demande ajoutés avec succès!': 'Request rates added successfully!'

}

const transFR = {
    'RequestPrices': 'Demander les tarifs',
    'requestPriceHeading1': "Nous vous remercions de votre demande de tarifs. Vos tarifs vous seront parvenus par mail dans les meilleurs délais. N’oubliez pas d’ouvrir un compte professionnel gratuit et sans engagement afin que vous puissiez bénéficier de vos tarifs et d’un service incomparable.",
    'companyName': 'Nom de la société',
    'How many shipments do you make per month': 'Combien d’expéditions faites vous par mois',
    'Are you an E-commerce': 'Etes vous un E-commerce',
    'What sector of activity': 'Quel secteur d’activité',
    'What is your current carrier': 'Quel est votre transporteur actuel',
    'Taux de demande ajoutés avec succès!': 'Taux de demande ajoutés avec succès!'

}


export default {
    en: transEN,
    fr: transFR
}
