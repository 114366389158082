const transEN = {
    'Thank you for placing order with EasyShip': 'Thank you for placing order with Easyship',
    'Order No': 'Order No',
    'Your Payment ID': 'Your Payment ID',
    'Approved': 'Approved',
    'Status': 'Status',
    'Success': 'Success',
    'Billing Weight': 'Billing Weight',
    'Shipping Charges': 'Shipping Charges',
    'Service Provider': 'Service Provider',
    'Shipment Details': 'Shipment Details',
    'Order Details': 'Order Details',
    'Sender Name': 'Sender Name',
    'Receiver Name': 'Receiver Name',
    'Order Type': 'Order Type',
    'Package Specification': 'Package Specification',
    'Package No.': 'Package No.',
    'Shipment Label': 'Shipment Label',
}

const transFR = {
    'Thank you for placing order with EasyShip': 'Merci d\'avoir passé commande avec Easyship',
    'Order No': 'N ° de commande',
    'Your Payment ID': 'Votre identifiant de paiement',
    'Approved': 'Approuvé',
    'Status': 'Statut',
    'Success': 'Succès',
    'Billing Weight': 'Poids de facturation',
    'Shipping Charges': 'Frais d\'expédition',
    'Service Provider': 'Transporteur',
    'Shipment Details': 'Détails de l\'envoi',
    'Order Details': 'Détails de la commande',
    'Sender Name': 'Nom de l\'expéditeur',
    'Receiver Name': 'Nom du destinataire',
    'Order Type': 'Type de commande',
    'Package Specification': 'Détails du colis',
    'Package No.': 'Paquet No.',
    'Shipment Label': 'Étiquette d\'expédition'

}

export default {
    en: transEN,
    fr: transFR
}
