const transEN = {
    'courierHeading1': '<0>Need to deliver your customers or </0> restock your stores urgently in Marrakech and Casablanca? The Courier offer <0>from Easyship allows you to deliver to your most impatient customers during the day </0> at very advantageous rates, <0>From 60 DH HT</0>.',

    'courierPara1': 'The <1> price </1> of your expedition is calculated according to the <1>kilometer distance</1> between your point of departure and arrival.',

    'courierHeading2': 'How it works ?',
    'courierPara2': 'Are you a <1>professional</1> (TPE-PME, e-trader, craftsman, self-employed person)? Create an Easyship customer account free of charge and without obligation. You will thus benefit from additional discounts on your mailings of envelopes, pallet parcels and will benefit from all our professional tools free of charge to facilitate your logistics at the local, national or international level.',
    'courierPara3': 'Go to the home page and enter the city of departure and destination as well as the weight and dimensions of the package (s) to be sent.',
    'courierListData': 'Your order is finalized, all you have to do is wait for the courier to arrive...'

}

const transFR = {
    'courierHeading1': '<0>Besoin de livrer vos clients ou de</0> réapprovisionner vos boutiques en\n' +
    '                                urgence à Marrakech et\n' +
    '                                Casablanca? L\'offre Coursier <0>d’Easyship vous permet de livrer dans la journée vos\n' +
    '                                clients\n' +
    '                                les plus impatients</0> à des tarifs très avantageux, <0>A partir de 60 DH\n' +
    '                                HT.</0>',
    'courierPara1': 'Le<1> tarif</1> de votre expédition est calculé en fonction de\n' +
    '                            la <1>distance kilométrique</1> qui sépare votre point de départ et d\'arrivée.',
    'courierHeading2': 'Comment ça marche ?',
    'courierPara2': ' Vous êtes un <1>professional</1> (TPE-PME, e-commerçant, artisan,\n' +
    '                                auto-entrepreneur) ? Créez\n' +
    '                                gratuitement et sans engagement un compte client Easyship. Vous bénéficierez ainsi de\n' +
    '                                remises supplémentaires sur vos envois de plis, colis palettes et profiterez\n' +
    '                                gratuitement de\n' +
    '                                tous nos outils professionnels facilitant votre logistique au niveau local, national ou\n' +
    '                                international.',
    'courierPara3': 'Rendez-vous en page d\'accueil et renseignez la ville de départ et de destination ainsi que le\n' +
    '                            poids et les dimensions du (des) colis à envoyer.',
    'courierListData': 'Votre commande est finalisée, il ne vous reste plus qu\'à attendre l\'arrivée du coursier...'

}


export default {
    en: transEN,
    fr: transFR
}
