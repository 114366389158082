const transEN = {
    'africaHeading1': 'THE BEST WAY TO SHIP A PARCEL TO AFRICA QUICKLY AND MORE ECONOMICALLY WITH EASYSHIP',
    'africaPara1': 'To send a parcel to Africa on Easyship.ma, enter the weight of your parcel as well as its dimensions in the search engine. Access the page where you can find the various services that we offer you, at very competitive prices, simply by clicking on “Compare Prices”. Then compare the offers of our partners and their prices, then make your choice. Depending on the carrier that will handle your shipment, you will be asked to print the shipment slip. The "Carriers" section allows you to know the different carriers working in collaboration with us, in particular DHL, UPS, FedEx ... Easyship also ensures the delivery of your shipments in Africa, in complete transparency since at any time, you can follow your parcel by clicking on',
    'africaSubPara1': '',
    'africaTransporteurs': '',
    'africaSubPara3': '',
    'africaSubPara2': '',
    'africaSubPara4': '',
    'africaHeading2': 'TIPS FOR SENDING A PARCEL TO AFRICA',
    'africaPara3': 'For all shipments to Africa, simple forms must be completed. Do not worry, we will take care of informing you of the procedures to follow and the documentation that you will need to provide depending on the country. Remember that it is necessary to include in the requested data, the telephone number of the person receiving the package; this helps to ensure that the package is delivered on the carrier\'s first pass. In some countries, we call the same person if the address is incomplete.',
    'africaPara4': 'You must also make sure to calculate the weight and dimensions of your package in order to be able to choose the type of services adapted to your package among the offers of our carriers.',
    'africaPara5': 'Remember to put your item in a cardboard box and protect it properly when you ship to Africa (choose adequate packaging and material that absorbs impacts, such as bubble wrap, polystyrene, etc.). Make sure the package is properly closed. If there is more than one item in the crate, pack each item separately, this will prevent items from getting damaged between them. The most delicate and heavy items will need good protection at the bottom of the crate, sides, and top of the carton.',
    'africaPara6': 'Please take into account that shipments to African countries go through customs. When your package goes through customs, customs officials see if there is a need to add an additional cost or import tax, based on the information on the commercial or pro-forma invoice, which should print from our site. You obviously want your package to arrive as soon as possible. To do this, you must ensure that the address and the shipment details are entered correctly. Place the labels and the destination address on top of the carton. Avoid putting them on closures, corners, or tape.',
    'africaHeading3': 'DEADLINES AND FORMALITIES FOR SENDING A PARCEL TO AFRICA',
    'africaPara7': 'Parcel shipments to Africa take an average of 3 days.',
    'africaPara8': 'Regarding customs formalities, Our Customer Service is at your service to answer your questions.',

    'Envoi de colis en Afrique du Sud': 'Ship a document or parcel South Africa',
    'Envoi de Document ou Colis en Algérie': 'Ship a document or parcel Algeria',
    'Envoi de Document ou Colis en Angola': 'Ship a document or parcel Angola',

    'Envoi de Document ou Colis au Burkina Faso': 'Ship a document or parcel Burkina Faso',

    'Envoi de Document ou Colis au Burundi': 'Ship a document or parcel Burundi',

    'Envoi de Document ou Colis au Bénin': 'Ship a document or parcel Benign',

    'Envoi de Document ou Colis au Cameroun': 'Ship a document or parcel Cameroon',

    'Envoi de Document ou Colis au Cap Vert': 'Ship a document or parcel green cap',

    'Envoi de Document ou Colis en Centrafrique': 'Ship a document or parcel Central African Republic',

    'Envoi de Document ou Colis aux Comores': 'Ship a document or parcel Comoros',

    'Envoi de Document ou Colis au Congo': 'Ship a document or parcel Congo',

    'Envoi de Document ou Colis en Côte d’Ivoire': 'Ship a document or parcel Ivory Coast',

    'Envoi de Document ou Colis à Djibouti': 'Ship a document or parcel Djibouti',

    'Envoi de Document ou Colis en Égypte': 'Ship a document or parcel Egypt',

    'Envoi de Document ou Colis en Éthiopie': 'Ship a document or parcel Ethiopia',

    'Envoi de Document ou Colis au Gabon': 'Ship a document or parcel Gabon',

    'Envoi de Document ou Colis en Gambie': 'Ship a document or parcel Gambia',

    'Envoi de Document ou Colis au Ghana': 'Ship a document or parcel Ghana',

    'Envoi de Document ou Colis en Guinée': 'Ship a document or parcel Guinea',

    'Envoi de Document ou Colis en Guinée Bissau': 'Ship a document or parcel Guinea Bissau',

    'Envoi de Document ou Colis au Kenya': 'Ship a document or parcel Kenya',

    'Envoi de Document ou Colis à Madagascar': 'Ship a document or parcel Madagascar',

    'Envoi de Document ou Colis au Mali': 'Ship a document or parcel Mali',

    'Envoi de Document ou Colis à l’île Maurice': 'Ship a document or parcel Mauritius',

    'Envoi de Document ou Colis en Mauritanie': 'Ship a document or parcel Mauritania',

    'Envoi de Document ou Colis à Mayotte': 'Ship a document or parcel Mayotte',

    'Envoi de Document ou Colis au Mozambique': 'Ship a document or parcel Mozambique',

    'Envoi de Document ou Colis au Niger': 'Ship a document or parcel Niger',

    'Envoi de Document ou Colis au Nigéria': 'Ship a document or parcel Nigeria',

    'Envoi de Document ou Colis en Ouganda': 'Ship a document or parcel Uganda',

    'Envoi de Document ou Colis en Rép. Démocratique du Congo': 'Ship a document or parcel Rep. Democratic of Congo',

    'Envoi de Document ou Colis à Réunion': 'Ship a document or parcel Réunion',

    'Envoi de Document ou Colis au Rwanda': 'Ship a document or parcel Rwanda',

    'Envoi de Document ou Colis au Sénégal': 'Ship a document or parcel Senegal',

    'Envoi de Document ou Colis en Tanzanie': 'Ship a document or parcel Tanzania',

    'Envoi de Document ou Colis en Tchad': 'Ship a document or parcel Chad',

    'Envoi de Document ou Colis au Togo': 'Ship a document or parcel Togo',

    'Envoi de Document ou Colis en Tunisie': 'Ship a document or parcel Tunisia'

}

const transFR = {
    'africaHeading1': 'COMMENT ENVOYER UN COLIS EN AFRIQUE RAPIDEMENT ET PAS CHER AVEC EASYSHIP',
    'africaPara1': 'Pour effectuer un envoi de colis en Afrique sur Easyship.ma, insérez le poids de votre colis ainsi que ses dimensions dans le moteur de recherche. Accédez à la page où se trouvent les divers services que nous vous offrons, à prix très compétitifs, simplement en cliquant sur “Comparez les Prix”. Comparez ensuite les offres de nos partenaires et leurs tarifs, puis faites votre choix. En fonction du transporteur qui se chargera de votre envoi, il vous sera demandé d’imprimer le bordereau d’envoi.',
    'africaSubPara1': 'La section',
    'africaTransporteurs': 'Transporteurs',
    'africaSubPara3': 'livraison de votre expéditions en Afrique',

    'africaSubPara2': 'permet de connaitre les différents\n' +
    '                            transporteurs travaillant en\n' +
        '                            collaboration avec nous, notamment DHL, UPS, FedEx… Easyship assure aussi bien la ',
    'africaSubPara4': ', en toute\n' +
    '                            transparence puisqu\'à tout\n' +
    '                            moment, vous pouvez suivre votre colis en cliquant sur « Suivi du Colis ».',
    'africaHeading2': 'CONSEILS POUR ENVOYER UN COLIS EN AFRIQUE',
    'africaPara3': 'Pour tous les envois en Afrique, il faut remplir des formulaires simples. Ne vous inquiétez pas, nous nous chargeons de vous informer des démarches à suivre et de la documentation que vous devrez fournir en fonction des pays. Souvenez-vous qu’il est nécessaire d’inclure dans les données demandées, le numéro de téléphone de la personne qui reçoit le colis ; cela aide à ce que le colis soit livré dès le premier passage du transporteur. Dans certains pays, nous appelons cette même personne si l’adresse est incomplète.',
    'africaPara4': 'Vous devez également veiller à bien calculer le poids et dimensions de votre colis afin de pouvoir choisir le type de services adapté à votre colis parmi les offres de nos transporteurs.',
    'africaPara5': 'N’oubliez pas de mettre votre article dans un carton et de le protéger correctement lorsque vous réalisez vos envois vers Afrique (choisissez un emballage adéquat et du matériel qui amortit les impacts, comme des films à bulles, du polystyrène, etc). Assurez-vous que le colis soit bien fermé. S’il y a plus d’un objet dans la caisse, emballez chacun des articles séparément, vous éviterez ainsi que les articles s’abîment entre eux. Les articles les plus délicats et les plus lourds auront besoin d’une bonne protection au fond de la caisse, les latéraux, et la partie supérieure du carton.',
    'africaPara6': 'Vous devrez prendre en compte le fait que les envois vers les pays Africains passent par la douane. Quand votre colis passe le contrôle de la douane, les douaniers voient s’il est nécessaire d’ajouter un coût supplémentaire ou une taxe d’importation, en fonction de l’information qui figure sur la facture commerciale ou pro-forma, qui devra s’imprimer depuis notre site. Vous souhaitez bien évidemment que votre colis arrive dans les plus brefs délais. Pour cela, vous devez faire attention à ce que l’adresse et les données de l’envoi soient indiquées correctement. Placez les étiquettes et l’adresse de destination sur le dessus du carton. Evitez de les mettre sur les fermetures, les coins, ou le ruban adhésif.',
    'africaHeading3': 'DELAIS ET FORMALITES POUR ENVOYER UN COLIS EN AFRIQUE',
    'africaPara7': 'Les envois de colis vers l’Afrique prennent en moyenne 3 jours.',
    'africaPara8': 'Concernant les formalités douanières, Notre Service Client est à votre service pour répondre à vos questions.',
    'Envoi de colis en Afrique du Sud': 'Envoi de colis en Afrique du Sud',
    'Envoi de Document ou Colis en Algérie': 'Envoi de Document ou Colis en Algérie',
    'Envoi de Document ou Colis en Angola': 'Envoi de Document ou Colis en Angola',

    'Envoi de Document ou Colis au Burkina Faso': 'Envoi de Document ou Colis au Burkina Faso',

    'Envoi de Document ou Colis au Burundi': 'Envoi de Document ou Colis au Burundi',

    'Envoi de Document ou Colis au Bénin': 'Envoi de Document ou Colis au Bénin',

    'Envoi de Document ou Colis au Cameroun': 'Envoi de Document ou Colis au Cameroun',

    'Envoi de Document ou Colis au Cap Vert': 'Envoi de Document ou Colis au Cap Vert',

    'Envoi de Document ou Colis en Centrafrique': 'Envoi de Document ou Colis en Centrafrique',

    'Envoi de Document ou Colis aux Comores': 'Envoi de Document ou Colis aux Comores',

    'Envoi de Document ou Colis au Congo': 'Envoi de Document ou Colis au Congo',

    'Envoi de Document ou Colis en Côte d’Ivoire': 'Envoi de Document ou Colis en Côte d’Ivoire',

    'Envoi de Document ou Colis à Djibouti': 'Envoi de Document ou Colis à Djibouti',

    'Envoi de Document ou Colis en Égypte': 'Envoi de Document ou Colis en Égypte',

    'Envoi de Document ou Colis en Éthiopie': 'Envoi de Document ou Colis en Éthiopie',

    'Envoi de Document ou Colis au Gabon': 'Envoi de Document ou Colis au Gabon',

    'Envoi de Document ou Colis en Gambie': 'Envoi de Document ou Colis en Gambie',

    'Envoi de Document ou Colis au Ghana': 'Envoi de Document ou Colis au Ghana',

    'Envoi de Document ou Colis en Guinée': 'Envoi de Document ou Colis en Guinée',

    'Envoi de Document ou Colis en Guinée Bissau': 'Envoi de Document ou Colis en Guinée Bissau',

    'Envoi de Document ou Colis au Kenya': 'Envoi de Document ou Colis au Kenya',

    'Envoi de Document ou Colis à Madagascar': 'Envoi de Document ou Colis à Madagascar',

    'Envoi de Document ou Colis au Mali': 'Envoi de Document ou Colis au Mali',

    'Envoi de Document ou Colis à l’île Maurice': 'Envoi de Document ou Colis à l’île Maurice',

    'Envoi de Document ou Colis en Mauritanie': 'Envoi de Document ou Colis en Mauritanie',

    'Envoi de Document ou Colis à Mayotte': 'Envoi de Document ou Colis à Mayotte',

    'Envoi de Document ou Colis au Mozambique': 'Envoi de Document ou Colis au Mozambique',

    'Envoi de Document ou Colis au Niger': 'Envoi de Document ou Colis au Niger',

    'Envoi de Document ou Colis au Nigéria': 'Envoi de Document ou Colis au Nigéria',

    'Envoi de Document ou Colis en Ouganda': 'Envoi de Document ou Colis en Ouganda',

    'Envoi de Document ou Colis en Rép. Démocratique du Congo': 'Envoi de Document ou Colis en Rép. Démocratique du Congo',

    'Envoi de Document ou Colis à Réunion': 'Envoi de Document ou Colis à Réunion',

    'Envoi de Document ou Colis au Rwanda': 'Envoi de Document ou Colis au Rwanda',

    'Envoi de Document ou Colis au Sénégal': 'Envoi de Document ou Colis au Sénégal',

    'Envoi de Document ou Colis en Tanzanie': 'Envoi de Document ou Colis en Tanzanie',

    'Envoi de Document ou Colis en Tchad': 'Envoi de Document ou Colis en Tchad',

    'Envoi de Document ou Colis au Togo': 'Envoi de Document ou Colis au Togo',

    'Envoi de Document ou Colis en Tunisie': 'Envoi de Document ou Colis en Tunisie'

}

export default {
    en: transEN,
    fr: transFR
}
