const transEN = {
    'doubleCheck.line1': 'If the dimensions or weight shown on your parcel are not actually correct, you risk having\n' +
    '              your parcel stopped at the depot; you will then be asked to pay for its return and will not\n' +
    '              be due a refund for the delivery service. To check them, please see',

    'How to weigh':'"How to weigh the object you are sending"',
    'and':'and',
    "How to measure your parcel":'"How to measure your parcel".',
    'PREPARE YOUR SHIPMENT CAREFULLY': 'PREPARE YOUR SHIPMENT CAREFULLY',
    'How to check your shipments ?': 'How to check your shipments ?',
    'It is important to check your package before sending.': 'It is important to check your package before sending.',
    'Double Check weight and dimensions are correct': 'Double Check weight and dimensions are correct',
    'Double Check the names and addresses of the sender and addressee': 'Double Check the names and addresses of the sender and addressee',
    'plsCompleteAddress': '<0>Please complete your addresses as much as possible.</0>',
    'Full Name or Company Name': 'Full Name or Company Name',
    'Street number, Street name': 'Street number, Street name',
    'Floor, Entryphone': 'Floor, Entryphone',
    'Postcode, Town': 'Postcode, Town',
    'Telephone number (ideally mobile)': 'Telephone number (ideally mobile)',
    'You should be aware': 'You should be aware that if the carrier cannot access the indicated address, he may consider the service was carried out and store the parcel in a depot. Picking up the parcel can only be done by the addressee and a charge is usually made.',
    'E-mail address': 'E-mail address',
    'delays due to an incomplete': 'Note : delays due to an incomplete or missing destination address are not the carrier\'s responsibility. Be as precise as possible and do not forget to give the addressee\'s phone number.',
    'In the case of absence,': 'In the case of absence, the carriers generally leave a card to say they called in the letter box, indicating the number of the parcel and the phone number to contact them to organise pick up or re-delivery. The information or requests for information may also be sent by E-mail.',
    'Deliveries are made during the day': 'Deliveries are made during the day, so consider having your parcels delivered to your workplace; it costs less and the parcels will be delivered in time',
    'Check the content and its packaging': 'Check the content and its packaging',
    'Remember that you are legally responsible': 'Remember that you are legally responsible for the items that you send; they should not be one of the items in the Prohibited and Controlled Items list.',
    'Please refer to the terms and conditions': 'Please refer to the terms and conditions of both Upela.com and the carrier to ensure that your parcel complies with the rules.',
    'Your parcel should be correctly labelled': 'Your parcel should be correctly labelled; please refer to the section \'Wrapping your parcels. It should be easy to move without risk for its contents or for the handlers. If the packaging rules are not followed, the parcel may be blocked at the carrier\'s ; the sender will have to pay for its recovery and the delivery charge will not be refunded.',
    'How to weigh my shipments ?': 'How to weigh my shipments ?',
    'It is not always easy to find the weight of an object,': 'It is not always easy to find the weight of an object, the weight of a parcel, especially when you do not have any scales. So here are some hints and tips to estimate the weight of the objects you are sending :',
    'It is useful to know that most Carriers base their prices on tranches of weight of 500 g.': 'It is useful to know that most Carriers base their prices on tranches of weight of 500 g.',
    '1 DVD': '1 DVD',
    '0.250 kilo': '0.250 kilo',
    '0.500 Kg': '0.500 Kg',
    'One pocket book': 'One pocket book',
    '0.200 kilo': '0.200 kilo',
    '0.500 kg': '0.500 kg',
    'A pair of trainers in a box': 'A pair of trainers in a box',
    '1.5 kilos': '1.5 kilos',
    '1.500 kg': '1.500 kg',
    'A 33cl can of drink': 'A 33cl can of drink',
    '0.400 kilo': '0.400 kilo',
    'A 13\'\' laptop computer': 'A 13\'\' laptop computer',
    '2 kilos': '2 kilos',
    '2.000 Kg': '2.000 Kg',
    'A 1.5 litre bottle of water': 'A 1.5 litre bottle of water',
    '1,6 kilos': '1,6 kilos',
    'A ream of A4 paper': 'A ream of A4 paper',
    'Examples of objects': 'Examples of objects',
    'Weight': 'Weight',
    'Billable Weight': 'Billable Weight',
    'We always advise rounding up the weight of your parcel': 'We always advise rounding up the weight of your parcel to the nearest kilo. Another tip for objects of more than a kilo; if you have bathroom scales, weigh yourself holding the object in your hands, then weigh yourself without the object - the difference will be the weight of the parcel.',

    'underestimateTheWeight': '<0>It is very important not to underestimate the weight of your parcel,</0> as if it is underestimated, there is a risk of the carrier refusing the parcel, or its delivery being delayed. A supplementary payment may be required from the recipient. We advise you to always round up the weight of your shipment to the next price threshold, and add about 15% to cover the packaging. Be careful to measure the dimensions accurately.',

    'How to measure my shipments?': 'How to measure my shipments?',
    'We always calculate the dimensions': ' We always calculate the dimensions of an object after being wrapped for transport.If your item is in a cardboard box:',
    'L = length of the longest side': 'L = length of the longest side',
    'I = width of the next longest side': 'I = width of the next longest side',
    'H = the height of the shortest side': 'H = the height of the shortest side',
    'If your item cannot be packed in a box': 'If your item cannot be packed in a box, and is cylindrical, the diameter of the cylinder is taken for both the height and width.',
    'To send a letter': 'To send a letter, measure just the length and width (L x W). Here are some measurements as a guide:',
    'A shoe box': 'A shoe box = L x W x H : 35 cm x 22 cm x 13 cm',
    'A sheet of A4 paper = L x W = 29.5 cm x 21.5 cm': 'A sheet of A4 paper = L x W = 29.5 cm x 21.5 cm',
    'A postcard = L x W = 16 cm x 11 cm': 'A postcard = L x W = 16 cm x 11 cm',
    'Warning': 'Warning',
    'shippingWaring': 'While you are filling up your information on Easyship Website, Please insert External dimensions of the carton, rather than the internal dimensions, usually communicated by suppliers.',
}

const transFR = {
    'doubleCheck.line1': 'Si les dimensions et/ou le poids indiqués de votre colis ne correspondent pas à la réalité, votre colis risque d’être bloqué à l’entrepôt, vous serez alors dans l’obligation de payer sa récupération et la prestation ne sera pas remboursée. Pour les vérifier, consulter',

    'How to weigh':'"Comment peser l’objet à envoyer"',
    'and':'et',
    'How to measure your parcel':'"Comment mesurer votre colis".',



    'PREPARE YOUR SHIPMENT CAREFULLY': 'Bien préparer son envoi',
    'How to check your shipments ?': 'Comment contrôler mon colis avant son envoi ?',
    'It is important to check your package before sending.': 'Il est importat de vérifier votre colis avant l\'envoi.',
    'Double Check weight and dimensions are correct': 'Contrôler la justesse des dimensions et du poids',
    'Double Check the names and addresses of the sender and addressee': 'Contrôler les noms et adresses d’expédition et de destination\n',
    'plsCompleteAddress': '<0>Prière de compléter vos adresses le plus possible.</0>',
    'Full Name or Company Name': 'Nom Complet ou Raison Sociale',
    'Street number, Street name': 'N° de rue, Nom de Rue',
    'Floor, Entryphone': 'Etage, Interphone',
    'Postcode, Town': 'Code Postal, Ville',
    'Telephone number (ideally mobile)': 'N° de Tel (idéalement portable)',
    'E-mail address': 'Adresse Email',
    'You should be aware': 'Sachez que si le transporteur ne peut pas accéder à l’adresse indiquée, il peut considérer que la prestation a été effectuée et stocker le colis dans un entrepôt. La récupération de ce colis ne pourra se faire que par le destinataire et en général elle est payante.',
    'delays due to an incomplete': 'A savoir : les retards dûs à l’absence du destinataire ou à une adresse incomplète ne sont pas de la responsabilité du transporteur. Soyez le plus précis possible et n’oubliez pas d’indiquer le n° de téléphone du destinataire de vos envois.',
    'In the case of absence,': 'En cas d’absence, les transporteurs laissent en général dans la boite aux lettres un avis de passage indiquant le n° du colis et le n° de téléphone auxquels les joindre pour organiser un enlèvement ou une re-livraison. Les informations ou demandes d’informations peuvent aussi être envoyées en SMS ou par Email.',
    'Deliveries are made during the day': 'Les livraisons s’effectuant la journée, pensez à vous faire envoyer vos colis sur votre lieu de travail, vous payerez moins cher et les colis seront livrés dans les temps.',
    'Check the content and its packaging': 'Contrôler le contenu et son emballage',
    'Remember that you are legally responsible': 'Nous vous rappelons que vous êtes légalement responsable des objets que vous expédiez, ils ne doivent pas faire partie de la liste des objets interdits ou réglementés.',
    'Please refer to the terms and conditions': 'Reportez-vous également au CGV de Easyship.ma et du transporteur pour vous assurer que votre colis est conforme au règlement.',
    'Your parcel should be correctly labelled': 'Votre colis doit être correctement emballé, reportez-vous au point "Emballer votre colis". Il doit pouvoir être déplacé facilement et sans risque pour l’objet et pour les manutentionnaires. Si les règles d’emballage ne sont pas respectées, le colis risque d’être bloqué chez le transporteur, l’expéditeur devra payer la récupération et la prestation ne sera pas remboursée.',
    'How to weigh my shipments ?': 'Comment peser mes envois ?',
    'It is not always easy to find the weight of an object,': 'Il n’est pas toujours simple de déterminer le poids d’un colis surtout lorsqu’on ne possède pas de balance.',
    'It is useful to know that most Carriers base their prices on tranches of weight of 500 g.': 'Alors voici quelques astuces pour estimer le poids de vos objets à envoyer : Ce qu’il faut savoir, c’est que la plupart des transporteurs se basent sur des tranches de poids de 500 g pour calculer le prix de l’expédition.',
    '1 DVD': '1 DVD',
    '0.250 kilo': '0.250 kg',
    '0.500 Kg': '0.500 Kg',
    'One pocket book': '1 livre de poche',
    '0.200 kilo': '0.200 kg',
    '0.500 kg': '0.500 kg',
    'A pair of trainers in a box': '1 paire de basket dans une boite',
    '1.5 kilos': '1.5 kg',
    '1.500 kg': '1.500 kg',
    'A 33cl can of drink': '1 canette de 33 cl',
    '0.400 kilo': '0.400 kg',
    'A 13\'\' laptop computer': '1 ordinateur portable 13"',
    '2 kilos': '2 kg',
    '2.000 Kg': '2.000 Kg',
    'A 1.5 litre bottle of water': '1 bouteille d\'eau 1,5 l',
    '1,6 kilos': '1,6 kg',
    'A ream of A4 paper': '1 ramette de papier A4',
    'Examples of objects': 'Exemples d\'objets ',
    'Weight': 'Poids ',
    'Billable Weight': 'Poids Facturable',
    'We always advise rounding up the weight of your parcel': 'Nous vous conseillons de toujours arrondir le poids de votre colis au kilo supérieur. Autre astuce pour les objets de plus d’un kilo : si vous disposez d’un pèse personne, il vous suffit de vous peser sans l’objet dans les mains puis avec, la différence de poids correspond au poids de votre colis.',
    'underestimateTheWeight': '<0>Il est très important de ne pas sous-estimer le poids de votre colis,</0> en effet, s\'il est sous évalué vous risquez de voir son envoi refusé ou son acheminement ralenti. La facturation d’un supplément pourrait aussi être réclamée au destinataire. Nous vous conseillons de toujours arrondir à la fourchette supérieure le poids de vos envois et d’y ajouter environs 15% pour l’emballage. Attention de bien renseigner les dimensions.',

    'How to measure my shipments?': 'Comment mesurer mes envois ?',
    'We always calculate the dimensions': ' On calcule toujours la dimension d’un objet dans l’emballage du transport. Si votre objet est dans un carton : ',
    'L = length of the longest side': 'L = longueur correspondant au plus grand côté',
    'I = width of the next longest side': 'I = largeur correspondant au côté intermédiaire',
    'H = the height of the shortest side': 'H = hauteur du plus petit côté',
    'If your item cannot be packed in a box': 'Si votre objet ne peut pas s’emballer dans un carton, et qu’il a une forme cylindrique, le diamètre du cylindre est considéré comme la hauteur et la largeur.',
    'To send a letter': 'Pour l’envoi d’un pli, mesurer uniquement la Longueur x la largeur (L x l). Voici quelques mesures de références :',
    'A shoe box': 'Une boite à chaussures = L x W x H : 35 cm x 22 cm x 13 cm',
    'A sheet of A4 paper = L x W = 29.5 cm x 21.5 cm': 'Une feuille A4 = L x W = 29.5 cm x 21.5 cm',
    'A postcard = L x W = 16 cm x 11 cm': 'Une carte postale = L x W = 16 cm x 11 cm',
    'Warning': 'Attention',
    'shippingWaring': 'Au moment de passer commande, inscrivez les dimensions extérieures de votre carton et non intérieures, souvent communiquées par les fournisseurs d\'emballage. Vous éviterez ainsi toute refacturation par le transporteur.',

}

export default {
    en: transEN,
    fr: transFR
}
