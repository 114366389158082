const transEN = {

    'fedexHeading1': 'Send a package or pallet with FedEx',
    'fedexHeading2': 'As one of the transport leaders in the world, Fedex offers you a wide range of services for\n' +
    '                            <3> sending and tracking your packages internationally, </3> whether it is <3>home collection, express delivery for the next day, hand delivery</3>,<3> package tracking </3> at each stage of transport, proof of delivery, etc. Also note that the parcel is\n' +
    '                            represented on 2 consecutive days in the absence of the recipient. In short, the comfort and quality of\n' +
    '                            service that can be expected from a transport giant.',
    'fedexPara1': 'With FedEx you can send at low prices internationally, to France, Spain, UK, Europe, Asia and America… More than 200 countries served!',
    'fedexHeading3': 'FedEx International Priority',
    'fedexPara2': 'Delivery times',
    'fedexPara3': 'easyship, between 1 and 3 days',
    'fedexPara4': 'Zones',
    'fedexPara5': 'to and from the rest of the world',
    'fedexPara6': 'Collection',
    'fedexPara7': 'at the address indicated or in deposit',
    'fedexPara8': 'Delivery',
    'fedexPara9': 'to the recipient',
    'fedexPara10': 'FedEx Tracking',
    'fedexPara11': 'Available online on our website and the FedEx website'
}

const transFR = {
    'fedexHeading1': 'Envoyer un colis ou une palette avec FedEx',
    'fedexHeading2': ' Comptant parmi les leaders du transport dans le monde, Fedex vous offre une large gamme de services pour\n' +
    '                            <3>l’envoi et le suivi de vos colis à l’International,</3> que ce soit l’<3>enlèvement à\n' +
    '                                domicile, l’envoi express pour le lendemain, la remise en main propre</3>, le <3>suivi du\n' +
    '                                colis</3> à chaque étape du transport, la preuve de livraison… Notez aussi que le colis est\n' +
    '                            représenté les 2 jours consécutifs en cas d’absence du destinataire. Bref, le confort et la qualité de\n' +
    '                            service que l’on peut attendre d’un géant du transport.',
    'fedexPara1': 'Avec FedEx vous pouvez envoyer à bas prix à l\'international, en France, Espagne, UK, Europe,\n' +
    '                            Asie et\n' +
    '                            Amérique… Plus que 200 pays desservis !',
    'fedexHeading3': 'FedEx International Priority',
    'fedexPara2': 'Délais de livraison',
    'fedexPara3': 'easyship, entre 1 et 3 jours',
    'fedexPara4': 'Zones',
    'fedexPara5': 'vers et depuis le reste du Monde',
    'fedexPara6': 'Enlèvement',
    'fedexPara7': 'à l\'adresse indiquée ou en dépôt',
    'fedexPara8': 'Livraison',
    'fedexPara9': 'chez le destinataire',
    'fedexPara10': 'Suivi FedEx',
    'fedexPara11': 'disponible en ligne sur notre site et le site web de FedEx'
}

export default {
    en: transEN,
    fr: transFR
}
