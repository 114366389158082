const transEN = {
    'oceaniaHeading1': 'WITH EASYSHIP, YOUR FAST AND ECONOMICAL SHIPMENTS IN OCEANIA',
    'oceaniaPara1': 'To send a parcel to Oceania on Easyship.ma, enter the weight of your parcel and its dimensions in the search engine. Access the page where you can find the various services that we offer you, at very competitive prices, simply by clicking on “Compare Prices”. Then compare the offers of our partners and their prices, then make your choice. Depending on the carrier that will handle your shipment, you will be asked to print the shipment slip. The "Carriers" section allows you to know the different carriers working in collaboration with us, in particular DHL, UPS, FedEx ... Easyship also ensures the delivery of your shipments in Oceania, in full transparency since at any time, you can follow your parcel by clicking on',
    'oceaniaSubPara1': '',
    'oceaniaTransporteurs': '',
    'oceaniaSubPara3': '',
    'oceaniaSubPara2': '',
    'oceaniaSubPara4': '',
    'oceaniaHeading2': 'TIPS FOR SENDING A PARCEL TO OCEANIA',
    'oceaniaPara3': 'For all shipments to Oceania, simple forms must be completed. Do not worry, we will take care of informing you of the procedures to follow and the documentation that you will need to provide depending on the country. Remember that it is necessary to include in the requested data, the telephone number of the person receiving the package; this helps to ensure that the package is delivered on the carrier\'s first pass. In some countries, we call the same person if the address is incomplete.',
    'oceaniaPara4': 'You must also make sure to calculate the weight and dimensions of your package in order to be able to choose the type of services adapted to your package among the offers of our carriers.',
    'oceaniaPara5': 'Remember to put your item in a cardboard box and protect it properly when shipping to Oceania (choose adequate packaging and material that absorbs impacts, such as bubble wrap, polystyrene, etc.). Make sure the package is properly closed. If there is more than one item in the crate, pack each item separately, this will prevent items from getting damaged between them. The most delicate and heavy items will need good protection at the bottom of the crate, sides, and top of the carton.',
    'oceaniaPara6': 'Please take into account that shipments to Oceania go through customs. When your package goes through customs, customs officials see if there is a need to add an additional cost or import tax, based on the information on the commercial or pro-forma invoice, which should print from our site. You obviously want your package to arrive as soon as possible. To do this, you must ensure that the address and the shipment details are entered correctly. Place the labels and the destination address on top of the carton. Avoid putting them on closures, corners, or tape.',
    'oceaniaHeading3': 'DEADLINES AND FORMALITIES FOR SENDING A PARCEL TO OCEANIA',
    'oceaniaPara7': 'Parcel shipments to Oceania take an average of 3 days.',
    'oceaniaPara8': 'Regarding customs formalities, Our Customer Service is at your service to answer your questions.',

    'Envoi de Document ou Colis en Australie': 'Ship a document or parcel to Australia',

    'Envoi de Document ou Colis aux Îles Wallis et Futuna': 'Ship a document or parcel to Wallis and Futuna Islands',

    'Envoi de Document ou Colis en Nouvelle Calédonie': 'Ship a document or parcel to New Caledonia',

    'Envoi de Document ou Colis en Nouvelle Zélande': 'Ship a document or parcel to New Zealand',

    'Envoi de Document ou Colis en Polynésie Française': 'Ship a document or parcel to French Polynesia',


}

const transFR = {
    'oceaniaHeading1': 'AVEC EASYSHIP, VOS ENVOIS RAPIDES ET ÉCONOMIQUES EN OCEANIE',
    'oceaniaPara1': 'Pour effectuer un envoi de colis en Océanie sur Easyship.ma, insérez le poids de votre colis ainsi que ses dimensions dans le moteur de recherche. Accédez à la page où se trouvent les divers services que nous vous offrons, à prix très compétitifs, simplement en cliquant sur “Comparez les Prix”. Comparez ensuite les offres de nos partenaires et leurs tarifs, puis faites votre choix. En fonction du transporteur qui se chargera de votre envoi, il vous sera demandé d’imprimer le bordereau d’envoi.',
    'oceaniaSubPara1': 'La section',
    'oceaniaTransporteurs': 'Transporteurs',
    'oceaniaSubPara3': 'livraison de votre expéditions en Océanie',

    'oceaniaSubPara2': 'permet de connaitre les différents\n' +
    '                            transporteurs travaillant en\n' +
        '                            collaboration avec nous, notamment DHL, UPS, FedEx… Easyship assure aussi bien la ',
    'oceaniaSubPara4': ', en toute\n' +
    '                            transparence puisqu\'à tout\n' +
    '                            moment, vous pouvez suivre votre colis en cliquant sur « Suivi du Colis ».',
    'oceaniaHeading2': 'CONSEILS POUR ENVOYER UN COLIS EN OCEANIE',
    'oceaniaPara3': 'Pour tous les envois en Océanie, il faut remplir des formulaires simples. Ne vous inquiétez pas, nous nous chargeons de vous informer des démarches à suivre et de la documentation que vous devrez fournir en fonction des pays. Souvenez-vous qu’il est nécessaire d’inclure dans les données demandées, le numéro de téléphone de la personne qui reçoit le colis ; cela aide à ce que le colis soit livré dès le premier passage du transporteur. Dans certains pays, nous appelons cette même personne si l’adresse est incomplète.',
    'oceaniaPara4': 'Vous devez également veiller à bien calculer le poids et dimensions de votre colis afin de pouvoir choisir le type de services adapté à votre colis parmi les offres de nos transporteurs.',
    'oceaniaPara5': 'N’oubliez pas de mettre votre article dans un carton et de le protéger correctement lorsque vous réalisez vos envois vers Océanie (choisissez un emballage adéquat et du matériel qui amortit les impacts, comme des films à bulles, du polystyrène, etc). Assurez-vous que le colis soit bien fermé. S’il y a plus d’un objet dans la caisse, emballez chacun des articles séparément, vous éviterez ainsi que les articles s’abîment entre eux. Les articles les plus délicats et les plus lourds auront besoin d’une bonne protection au fond de la caisse, les latéraux, et la partie supérieure du carton.',
    'oceaniaPara6': 'Vous devrez prendre en compte le fait que les envois vers l’Océanie passent par la douane. Quand votre colis passe le contrôle de la douane, les douaniers voient s’il est nécessaire d’ajouter un coût supplémentaire ou une taxe d’importation, en fonction de l’information qui figure sur la facture commerciale ou pro-forma, qui devra s’imprimer depuis notre site. Vous souhaitez bien évidemment que votre colis arrive dans les plus brefs délais. Pour cela, vous devez faire attention à ce que l’adresse et les données de l’envoi soient indiquées correctement. Placez les étiquettes et l’adresse de destination sur le dessus du carton. Evitez de les mettre sur les fermetures, les coins, ou le ruban adhésif.',
    'oceaniaHeading3': 'DELAIS ET FORMALITES POUR ENVOYER UN COLIS EN OCEANIE',
    'oceaniaPara7': 'Les envois de colis vers l’ Océanie prennent en moyenne 3 jours.',
    'oceaniaPara8': 'Concernant les formalités douanières, Notre Service Client est à votre service pour répondre à vos questions.',
    'Envoi de Document ou Colis en Australie': 'Envoi de Document ou Colis en Australie',

    'Envoi de Document ou Colis aux Îles Wallis et Futuna': 'Envoi de Document ou Colis aux Îles Wallis et Futuna',

    'Envoi de Document ou Colis en Nouvelle Calédonie': 'Envoi de Document ou Colis en Nouvelle Calédonie',

    'Envoi de Document ou Colis en Nouvelle Zélande': 'Envoi de Document ou Colis en Nouvelle Zélande',

    'Envoi de Document ou Colis en Polynésie Française': 'Envoi de Document ou Colis en Polynésie Française',


}

export default {
    en: transEN,
    fr: transFR
}
