const transEN = {
    'Online Live tracking': 'Online Live tracking',
    'Door to door Service provided': 'Door to door Service provided',
    'Insurance Coverage through Carrier': 'Insurance Coverage through Carrier',
    'Insurance Coverage through third party': 'Insurance Coverage through third party',
    'DELIVERY': 'DELIVERY',
    'PICKUP': 'PICKUP',
    'choosePartner': 'Choose the carrier of your choice'

}

const transFR = {
    'Online Live tracking': 'Suivi En-ligne Disponible',
    'Door to door Service provided': 'Service porte a porte',
    'Insurance Coverage through Carrier': 'Assurance Fournie Par le transporteur',
    'Insurance Coverage through third party': 'Assurance Fournie par une compagnie d’assurance',
    'DELIVERY': 'Arrivée',
    'PICKUP': 'Départ',
    'choosePartner': 'Choisissez le transporteur de votre choix'
}

export default {
    en: transEN,
    fr: transFR
}
