const transEN = {

    ////////whyuseEasyship

    'A fast and reliable International': 'A fast and reliable International Express Shipping Service. Sophisticated tools as real-time shipment Tracking, Transit Times and Customs Tools with International Document preparation. Signature Proof of delivery on every Shipment you send and receive. Effective and fast Customs clearance.',
    'Our Rates': 'Our Rates',
    'We offer the best rates in the market for BtoB and BtoC': 'We offer the best rates in the market for BtoB and BtoC',
    'Our experience': 'Our experience',
    'Our 20+ Years of experience in import/export is at your service every step of the way': 'Our 20+ Years of experience in import/export is at your service every step of the way',
    'The Carrier choice': 'The Carrier choice',
    'Chose your favorite carrier or one with the best cost': 'Chose your favorite carrier or one with the best cost',
    'Simplicity': 'Simplicity',
    'Your shipments in a few clicks, with no obligation to open an account, always with our best service and rates': 'Your shipments in a few clicks, with no obligation to open an account, always with our best service and rates',
    'Optimised purchasing': 'Optimised purchasing',
    'Use us as the Middle man with the better of the leaders in international express delivery': 'Use us as the Middle man with the better of the leaders in international express delivery',
    'Choice of destination': 'Choice of destination',
    'Export/ Import to and from 220 countries': 'Export/ Import to and from 220 countries',
    'Support Quality': 'Support Quality',
    'Door to door service, guaranteed next day delivery, parcel tracking and an efficient customer support at your service': 'Door to door service, guaranteed next day delivery, parcel tracking and an efficient customer support at your service',
    'Extra discounts': 'Extra discounts',
    'The more you Ship, the more discounts you enjoy! If you open an account, you can benefit from discounts of up to 75%': 'The more you Ship, the more discounts you enjoy! If you open an account, you can benefit from discounts of up to 75%',
    'Multiple Shipping Service': 'Multiple Shipping Service',
    'Express Cost is out of budget? No problem, ask for a quote in Airfreight, Ground or Sea Transportation': 'Express Cost is out of budget? No problem, ask for a quote in Airfreight, Ground or Sea Transportation',
    'Tracking': 'Tracking',
    'Shipment Tracking takes place here on this website regardless of the carrier': 'Shipment Tracking takes place here on this website regardless of the carrier',
    'Home Pick Up': 'Home Pick Up',
    'Tell us the date and time of your convenience, and we are there to pick up your shipment': 'Tell us the date and time of your convenience, and we are there to pick up your shipment',
    'Freight Forwarder?': 'Freight Forwarder?',
    'Ask us for your Access Pro. You will never look back.': 'Ask us for your Access Pro. You will never look back.',

}

const transFR = {

    'A fast and reliable International': '',
    'Our Rates': 'Nos Tarifs',
    'We offer the best rates in the market for BtoB and BtoC': 'Nous proposons les tarifs les moins chers pour tous vos envois internationaux en BtoB et BtoC.',
    'Our experience': 'Notre expérience',
    'Our 20+ Years of experience in import/export is at your service every step of the way': 'Nos 20 ans d’expérience dans l’import / export vous garantissent un savoir-faire inégalé dans le domaine',
    'The Carrier choice': 'Le Choix de transporteur',
    'Chose your favorite carrier or one with the best cost': 'Choisissez votre transporteur préféré ou le moins cher.',
    'Simplicity': 'Simplicité',
    'Your shipments in a few clicks, with no obligation to open an account, always with our best service and rates': 'Votre envoi en quelques clics, sans obligation d’ouverture de compte, toujours avec nos meilleurs services',
    'Optimised purchasing': 'Optimisation achats',
    'Use us as the Middle man with the better of the leaders in international express delivery': 'Bénéficiez par notre intermédiaire du meilleur des leaders du transport express international',
    'Choice of destination': 'Choix de destinations',
    'Export/ Import to and from 220 countries': '220 Pays à l’import / export.',
    'Support Quality': 'La qualité du Support',
    'Door to door service, guaranteed next day delivery, parcel tracking and an efficient customer support at your service': 'Le service porte à porte, suivi colis et un service client efficace à votre disposition',
    'Extra discounts': 'Remises en plus',
    'The more you Ship, the more discounts you enjoy! If you open an account, you can benefit from discounts of up to 75%': 'Plus vous envoyez, moins vous payez ! Si vous créez un compte, bénéficiez de remises jusqu\'à 75% !',
    'Multiple Shipping Service': 'Multiple choix de Mode de Transport',
    'Express Cost is out of budget? No problem, ask for a quote in Airfreight, Ground or Sea Transportation': 'Le transport Express est hors budget ? Demandez vos cotation en fret Aérien, Routier ou Maritime.',
    'Tracking': 'Suivi',
    'Shipment Tracking takes place here on this website regardless of the carrier': 'Le suivi de vos colis se fait dans ce même site peu importe le transporteur de votre choix.',
    'Home Pick Up': 'Ramassage a Domicile',
    'Tell us the date and time of your convenience, and we are there to pick up your shipment': 'Dites nous la date et temps a votre convenance, et nous nous occupons du reste.',
    'Freight Forwarder?': 'Professionnel de Transport',
    'Ask us for your Access Pro. You will never look back.': 'Demandez votre formule revendeur, votre satisfaction garantie !',


}


export default {
    en: transEN,
    fr: transFR
}
