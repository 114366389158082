const initialState = {
  token: null,
  user: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.data
      }

    case 'SET_USER':
      return {
        ...state,
        user: action.data
      }

    default:
      return state
  }
}
