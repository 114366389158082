const transEN = {
    'northAmericaHeading1': 'FAST AND ECONOMICAL SHIPPING TO NORTH AMERICA WITH EASYSHIP',
    'northAmericaPara1': 'To send a parcel to North America on Easyship.ma, enter the weight of your parcel as well as its dimensions in the search engine. Access the page where you can find the various services that we offer you, at very competitive prices, simply by clicking on “Compare Prices”. Then compare the offers of our partners and their prices, then make your choice. Depending on the carrier that will handle your shipment, you will be asked to print the shipment slip. The "Carriers" section allows you to find out about the various carriers working in collaboration with us, in particular DHL, UPS, FedEx ... Easyship also ensures the delivery of your shipments in North America, in complete transparency since at any time, you can track your parcel by clicking on',
    'northAmericaSubPara1': '',
    'northTransporteurs': '',
    'northAmericaSubPara3': '',
    'northAmericaSubPara2': '',
    'northAmericaSubPara4': '',
    'northAmericaHeading2': 'TIPS FOR SENDING A PARCEL TO NORTH AMERICA',
    'northAmericaPara3': 'For all shipments to North America, simple forms must be completed. Do not worry, we will take care of informing you of the procedures to follow and the documentation that you will need to provide depending on the country. Remember that it is necessary to include in the requested data, the telephone number of the person receiving the package; this helps to ensure that the package is delivered on the carrier\'s first pass. In some countries, we call the same person if the address is incomplete.',
    'northAmericaPara4': 'You must also make sure to calculate the weight and dimensions of your package in order to be able to choose the type of services adapted to your package among the offers of our carriers.',
    'northAmericaPara5': 'Remember to put your item in a cardboard box and protect it properly when shipping to North America (choose adequate packaging and material that absorbs impacts, such as bubble wrap, polystyrene, etc.). Make sure the package is properly closed. If there is more than one item in the crate, pack each item separately, this will prevent items from getting damaged between them. The most delicate and heavy items will need good protection at the bottom of the crate, sides, and top of the carton.',
    'northAmericaPara6': 'Please take into account that shipments to North America go through customs. When your package goes through customs, customs officials see if there is a need to add an additional cost or import tax, based on the information on the commercial or pro-forma invoice, which should print from our site and accompany your package. You obviously want your package to arrive as soon as possible. To do this, you must ensure that the address and the shipment details are entered correctly. Place the labels and the destination address on top of the carton. Avoid putting them on closures, corners, or tape.',
    'northAmericaPara7': 'To send a package, a few rules must be observed. In the event that you are sending food products to the USA, you will need to register them with the Food and Drug Administration (FDA). Consult us for details ...',
    'northAmericaHeading3': 'DEADLINES AND FORMALITIES FOR SENDING A PARCEL TO NORTH AMERICA',
    'northAmericaPara8': 'Parcel shipments to North America take an average of 3 days.',
    'northAmericaPara9': 'Regarding customs formalities, Our Customer Service is at your service to answer your questions',
    'Envoi de Document ou Colis au Canada': 'Ship a document or parcel to Canada',
    'Envoi de Document ou Colis au Costa Rica': 'Ship a document or parcel to Costa Rica',
    'Envoi de Document ou Colis à Cuba': 'Ship a document or parcel to Cuba',
    'Envoi de Document ou Colis aux États Unis': 'Ship a document or parcel to United States',
    'Envoi de Document ou Colis en Guadeloupe': 'Ship a document or parcel to Guadeloupe',
    'Envoi de Document ou Colis à Haïti': 'Ship a document or parcel to Haiti',
    'Envoi de Document ou Colis en Martinique': 'Ship a document or parcel to Martinique',
    'Envoi de Document ou Colis au Mexique': 'Ship a document or parcel to Mexico',
    'Envoi de Document ou Colis au Panama': 'Ship a document or parcel to Panama',
    'Envoi de Document ou Colis en République Dominicaine': 'Ship a document or parcel to Dominican Republic',
    'Envoi de Document ou Colis à Saint Barthélemy': 'Ship a document or parcel to Saint Barthélemy',
    'Envoi de Document ou Colis à Saint Martin (France)': 'Ship a document or parcel to Saint Martin (France)',
}

const transFR = {
    'northAmericaHeading1': 'EXPÉDITION RAPIDE ET ÉCONOMIQUE EN AMÉRIQUE DU NORD AVEC EASYSHIP',
    'northAmericaPara1': 'Pour effectuer un envoi de colis en Amérique du Nord sur Easyship.ma, insérez le poids de votre colis ainsi que ses dimensions dans le moteur de recherche. Accédez à la page où se trouvent les divers services que nous vous offrons, à prix très compétitifs, simplement en cliquant sur “Comparez les Prix”. Comparez ensuite les offres de nos partenaires et leurs tarifs, puis faites votre choix. En fonction du transporteur qui se chargera de votre envoi, il vous sera demandé d’imprimer le bordereau d’envoi.',
    'northAmericaSubPara1': 'La section',
    'northTransporteurs': 'Transporteurs',
    'northAmericaSubPara3': 'livraison de votre expéditions en Amérique du Nord',

    'northAmericaSubPara2': 'permet de connaitre les différents\n' +
    '                            transporteurs travaillant en\n' +
        '                            collaboration avec nous, notamment DHL, UPS, FedEx… Easyship assure aussi bien la ',
    'northAmericaSubPara4': ', en toute\n' +
    '                            transparence puisqu\'à tout\n' +
    '                            moment, vous pouvez suivre votre colis en cliquant sur « Suivi du Colis ».',
    'northAmericaHeading2': 'CONSEILS POUR ENVOYER UN COLIS EN AMÉRIQUE DU NORD\n',
    'northAmericaPara3': 'Pour tous les envois en Amérique du Nord, il faut remplir des formulaires simples. Ne vous inquiétez pas, nous nous chargeons de vous informer des démarches à suivre et de la documentation que vous devrez fournir en fonction des pays. Souvenez-vous qu’il est nécessaire d’inclure dans les données demandées, le numéro de téléphone de la personne qui reçoit le colis ; cela aide à ce que le colis soit livré dès le premier passage du transporteur. Dans certains pays, nous appelons cette même personne si l’adresse est incomplète.',
    'northAmericaPara4': 'Vous devez également veiller à bien calculer le poids et dimensions de votre colis afin de pouvoir choisir le type de services adapté à votre colis parmi les offres de nos transporteurs.',
    'northAmericaPara5': 'N’oubliez pas de mettre votre article dans un carton et de le protéger correctement lorsque vous réalisez vos envois en Amérique du Nord (choisissez un emballage adéquat et du matériel qui amortit les impacts, comme des films à bulles, du polystyrène, etc). Assurez-vous que le colis soit bien fermé. S’il y a plus d’un objet dans la caisse, emballez chacun des articles séparément, vous éviterez ainsi que les articles s’abîment entre eux. Les articles les plus délicats et les plus lourds auront besoin d’une bonne protection au fond de la caisse, les latéraux, et la partie supérieure du carton.',
    'northAmericaPara6': 'Vous devrez prendre en compte le fait que les envois en Amérique Du Nord passent par la douane. Quand votre colis passe le contrôle de la douane, les douaniers voient s’il est nécessaire d’ajouter un coût supplémentaire ou une taxe d’importation, en fonction de l’information qui figure sur la facture commerciale ou pro-forma, qui devra s’imprimer depuis notre site et accompagner votre colis. Vous souhaitez bien évidemment que votre colis arrive dans les plus brefs délais. Pour cela, vous devez faire attention à ce que l’adresse et les données de l’envoi soient indiquées correctement. Placez les étiquettes et l’adresse de destination sur le dessus du carton. Evitez de les mettre sur les fermetures, les coins, ou le ruban adhésif.',
    'northAmericaPara7': 'Pour envoyer un colis, quelques règles sont à respecter. Dans le cas où vous envoyez des produits alimentaires aux USA, vous devrez les enregistrer auprès de La Food and Drug Administration (FDA). Consultez nous pour les détails…',
    'northAmericaHeading3': 'DELAIS ET FORMALITES POUR ENVOYER UN COLIS EN AMERIQUE DU NORD',
    'northAmericaPara8': 'Les envois de colis vers l’Amérique Du Nord prennent en moyenne 3 jours.',
    'northAmericaPara9': 'Concernant les formalités douanières, Notre Service Client est à votre service pour répondre à vos questions',
    'Envoi de Document ou Colis au Canada': 'Envoi de Document ou Colis au Canada',
    'Envoi de Document ou Colis au Costa Rica': 'Envoi de Document ou Colis au Costa Rica',
    'Envoi de Document ou Colis à Cuba': 'Envoi de Document ou Colis à Cuba',
    'Envoi de Document ou Colis aux États Unis': 'Envoi de Document ou Colis aux États Unis',
    'Envoi de Document ou Colis en Guadeloupe': 'Envoi de Document ou Colis en Guadeloupe',
    'Envoi de Document ou Colis à Haïti': 'Envoi de Document ou Colis à Haïti',
    'Envoi de Document ou Colis en Martinique': 'Envoi de Document ou Colis en Martinique',
    'Envoi de Document ou Colis au Mexique': 'Envoi de Document ou Colis au Mexique',
    'Envoi de Document ou Colis au Panama': 'Envoi de Document ou Colis au Panama',
    'Envoi de Document ou Colis en République Dominicaine': 'Envoi de Document ou Colis en République Dominicaine',
    'Envoi de Document ou Colis à Saint Barthélemy': 'Envoi de Document ou Colis à Saint Barthélemy',
    'Envoi de Document ou Colis à Saint Martin (France)': 'Envoi de Document ou Colis à Saint Martin (France)',

}

export default {
    en: transEN,
    fr: transFR
}
