export default {}

// let mainURL = 'http://63.142.251.167:8040'
let mainURL = 'http://localhost:8040'
//  let mainURL = 'https://api.easyship.ma'
if (process.env.NODE_ENV == 'production') {
  // mainURL = 'http://63.142.251.167:8040'
  mainURL = 'https://api.easyship.ma'
}

export const apiUrl = mainURL
