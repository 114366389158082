const transEN = {

    'Send us a referral and earn rewards towards free shipments': 'Send us a referral and earn rewards towards free shipments',
    'In order to qualify, you have to be registered (free account, without commitment).': 'In order to qualify, you have to be registered (free account, without commitment).',
    'The referred Customer': 'The referred Customer must indicate name and email address when creating a professional account. They automatically receive 200 MAD on their Easyship Pro Account.',
    'After the Referred Customer spends 2000 MAD in shipping, you earn 400 MAD Credit to your Easyship Pro Account.': 'After the Referred Customer spends 2000 MAD in shipping, you earn 400 MAD Credit to your Easyship Pro Account.',
    'Terms and Conditions': 'Terms and Conditions',
    'Offer cannot be combined with other commercial operations': 'Offer cannot be combined with other commercial operations',
    'Valid once the Referral': 'Valid once the Referral has made 2000 MAD of shipping on Easyship Pro. The Referral will receive a notification by email and will see his credit directly on his Easyship account',
    'The Sponsored Person must': 'The Sponsored Person must not be an employee from the same company (or any subsidiary company belonging to the same group) as his Referral.',
    'A sponsor cannot sponsor himself': 'A sponsor cannot sponsor himself',
    'The email address from the Referred Person must be different from the email address of the referring party.': 'The email address from the Referred Person must be different from the email address of the referring party.',
    'Step 1.': 'Step 1.',
    'Step 2.': 'Step 2.',
    'Step 3.': 'Step 3.',

}

const transFR = {
    'Send us a referral and earn rewards towards free shipments': 'Envoyez-nous une référence et gagnez des récompenses pour des envois gratuits',
    'In order to qualify, you have to be registered (free account, without commitment).': 'Pour être admissible, vous devez être enregistré (compte gratuit, sans engagement).',
    'The referred Customer': '\n' +
    'Le Client référé doit indiquer son nom et son adresse e-mail lors de la création d\'un compte professionnel. Ils reçoivent automatiquement 200 MAD sur leur compte Easyship Pro.',

    'After the Referred Customer spends 2000 MAD in shipping, you earn 400 MAD Credit to your Easyship Pro Account.': '\n' +
    'Une fois que le client référé a dépensé 2000 MAD en frais de port, vous gagnez 400 crédits MAD sur votre compte Easyship Pro.',
    'Terms and Conditions': 'Termes et conditions',
    'Offer cannot be combined with other commercial operations': 'L\'offre n\'est pas cumulable avec d\'autres opérations commerciales',
    'Valid once the Referral': 'Valable une fois que le filleul a effectué 2000 MAD d\'expédition sur Easyship Pro. Le Parrain recevra une notification par email et verra son crédit directement sur son compte Easyship',
    'The Sponsored Person must': 'Le parrainé ne doit pas être un employé de la même entreprise (ou d\'une filiale appartenant au même groupe) que son parrain.',
    'A sponsor cannot sponsor himself': 'Un parrain ne peut pas se parrainer',

    'The email address from the Referred Person must be different from the email address of the referring party.': 'L\'adresse e-mail de la personne référée doit être différente de l\'adresse e-mail de la partie référente.',
    'Step 1.': 'Étape 1.',
    'Step 2.': 'Étape 2.',
    'Step 3.': 'Étape 3.'
}


export default {
    en: transEN,
    fr: transFR
}
