const initialState = {
  btnLoading: false,
  cardLoading: false,
  loginLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case 'SHOW_BTN_LOADER':
      return {
        ...state,
        btnLoading: true
      }

    case 'HIDE_BTN_LOADER':
      return {
        ...state,
        btnLoading: false
      }

    case 'SHOW_CARD_LOADER':
      return {
        ...state,
        cardLoading: true
      }

    case 'HIDE_CARD_LOADER':
      return {
        ...state,
        cardLoading: false
      }

    case 'SHOW_LOGIN_LOADER':
      return {
        ...state,
        loginLoading: true
      }

    case 'HIDE_LOGIN_LOADER':
      return {
        ...state,
        loginLoading: false
      }

    default:
      return state
  }
}
