const transEN = {

    'AramexHeading1': 'Send a parcel with aramex: offers, express shipping',
    'AramexHeading2': 'From export to import via the Domestic Express service, Aramex offers a range of express shipping options',
    'AramexHeading3': 'Aramex Express services',

    'AramexPara1': 'Express Export',
    'AramexPara2': 'When your package needs to arrive as quickly as possible, opt for export services for urgent door-to-door shipping. With Express Export, you can ship your packages of all sizes anywhere in the world.',
    'AramexPara3': 'Express Import',
    'AramexPara4': 'Aramex provides an integrated door-to-door solution for importing urgent documents and parcels from around the world to meet your needs.',
    'AramexPara5': 'Domestique Express',
    'AramexPara6': 'The Domestique Easyship service in collaboration with aramex offers door-to-door solutions for urgent parcels that must be delivered to Morocco. We can collect and deliver your packages within the agreed time and you can follow the progress online at any time'
}

const transFR = {
    'AramexHeading1': 'Envoyer un colis avec aramex : offres, envoi express',
    'AramexHeading2': 'De l\'export à l\'import en passant par le service Domestique Express, Aramex propose un\n' +
    '                            éventail\n' +
    '                            d\'options d\'expédition express',
    'AramexHeading3': 'Les services Express d\'Aramex',

    'AramexPara1': 'Exportation Express',
    'AramexPara2': 'Lorsque votre colis doit arriver dans les plus brefs délais,\n' +
    '                        optez pour\n' +
    '                        les\n' +
    '                        services export pour une expédition urgente de porte-à-porte. Avec Exportation Express, vous\n' +
    '                        pouvez\n' +
    '                        expédier vos colis de toutes tailles n\'importe où dans le monde.',
    'AramexPara3': 'Importation Express',
    'AramexPara4': 'Aramex fournit une solution « porte-à-porte » intégrée pour\n' +
    '                        l\'importation de\n' +
    '                        documents et colis urgents depuis le monde entier, afin de répondre à vos besoins',
    'AramexPara5': 'Domestique Express',
    'AramexPara6': 'Le service Domestique Easyship en collaboration avec aramex\n' +
    '                        offre des\n' +
    '                        solutions porte-à-porte pour les colis urgents qui doivent être livrés au Maroc. Nous pouvons\n' +
    '                        collecter et\n' +
    '                        livrer vos colis dans le temps convenu et vous pouvez en suivre la progression en ligne à tout\n' +
    '                        moment'
}

export default {
    en: transEN,
    fr: transFR
}
