const transEN = {
    'openAccountHeading2': 'Easyship provides you with an incomparable Webship shipping portal. Simply complete the request below to receive the login and password for your account free of charge and without obligation. Save at least 10% on your freight bills.',
    'Contact Name': 'Contact Name',
    'Function': 'Function',
    'Collection Manager': 'Collection Manager',
    'Patent': 'Patent',
    'RC number': 'RC number',
    'ICE number': 'ICE number',
    'Date': 'Date',
    'termsCondition': ' terms and conditions',
    'I agree to': 'I agree to',
    'Submit': 'Submit',
    'Address': 'Address',
    'City': 'City',
    'Postal Code': 'Postal Code',
    'compte créé avec succès!' : 'Account created successfully!',
}

const transFR = {
    'openAccountHeading2': 'Easyship met à votre disposition un portail d’expédition Webship incomparable. Simplement remplissez la demande ci-après pour recevoir le login et mot de passe pour votre compte gratuit et sans engagement. Economiser au moins 10% sur vos factures de transport.',
    'Contact Name': 'Nom du contact',
    'Function': 'Fonction',
    'Collection Manager': 'Responsable Recouvrement',
    'Patent': 'Patente',
    'RC number': 'Numéro RC',
    'ICE number': 'Numéro ICE',
    'Date': 'Dated',
    'termsCondition': 'termes et conditions',
    'I agree to': 'Je suis d\'accord pour',
    'Submit': 'Soumettre',
    'Address': 'Adresse',
    'City': 'Ville',
    'Postal Code':'Code postal',
    'compte créé avec succès!' : 'compte créé avec succès!'
}


export default {
    en: transEN,
    fr: transFR
}
