import React from 'react'
import { Route, Redirect, Link, BrowserRouter, Switch } from 'react-router-dom'
import Analytics from 'react-router-ga'
// import Home from '../home'
// import SignUp from '../signUp'
// import Login from '../login'
// import UpdateProfile from '../signUp/updateProfile'
// import QuoteList from '../quotes/index'
// import ViewQuote from '../quotes/viewQuotes'
// import ProhibitedGoods from '../footerFiles/prohibitedGoods'
// import CustomObligations from '../footerFiles/customObligations'
// import PrepareShipment from '../footerFiles/prepareShipment'
// import WhyUseEasyship from '../footerFiles/whyUseEasyShip'
// import ProhibitedGoodsFrench from '../footerFiles/prohibitedGoodsFrench'
// import TermsAndConditions from '../../containers/Terms&Condition/index'
// import ContactUs from '../../containers/contactUs/index'

// import OpenAccount from '../easyshipPro/openAccount'
// import SendReferral from '../easyshipPro/sendReferral'
// import Success from '../paymentResponse/success'
// import Failed from '../paymentResponse/failed'
// import Payment from '../payment/index'
// import ShipmentHistory from '../shippingHistory/index'
// import ViewHistory from '../shippingHistory/viewHistory'
// import TransportNational from '../Carriers/national/transportNational'
// import CourierService from '../Carriers/national/courierService'
// import EuropeShipment from '../Carriers/interNational/europeShipment'
// import NorthAmericaShipment from '../Carriers/interNational/northAmericaShipment'
// import AfricaShipment from '../Carriers/interNational/africaShipment'
// import AsiaShipment from '../Carriers/interNational/asiaShipment'
// import SouthAmericaShipment from '../Carriers/interNational/southAmericaShipment'
// import OceaniaShipment from '../Carriers/interNational/oceaniaShipment'
// import ForgotPassword from '../forgotPassword/index'
// import terms from '../../containers/termsForOnlineSales/index'
// import DHLPage from '../../containers/Carriers/DHL/index'
// import FedexPage from '../../containers/Carriers/fedex/index'
// import UpsPage from '../../containers/Carriers/UPS/index'
// import AramexPage from '../../containers/Carriers/Aramex/index'
// import DHLTrackingPage from '../Carriers/Tracking'
// import RequestPrices from '../easyshipPro/requsetPrices'
// import AddressBook from '../../containers/addressBook'
// import paymentSelect from '../../containers/order/selectPayment'
// import SaleTerms from '../../containers/order/terms'
// import PaymentMethod from '../../containers/paymentMethod'
// import PendingShipments from '../../containers/shippingHistory/pendingShipments'
// import CancelShipments from '../../containers/shippingHistory/cancelShipments'
// import ErrorPage from '../../containers/fourZeroFour'
// import PrivacyPolicy from '../footerFiles/privacyPolicy'
// import AboutUs from '../footerFiles/aboutUs'

import Request from '../../request';
import Loadable from '@loadable/component';

const Loading = () => {
    return <div>Loading...</div>
}

//const OtherComponent = loadable(() => import('./OtherComponent'))

const Home = Loadable( () => import('../home')
);
const Offers = Loadable( () => import('../offers')
);
const Order = Loadable( () => import('../order')
);
const SignUp = Loadable( () => import('../signUp')
);
const Login = Loadable( () => import('../login')
);
const UpdateProfile = Loadable( () => import('../signUp/updateProfile')
);
const QuoteList = Loadable( () => import('../quotes/index')
);
const ViewQuote = Loadable( () => import('../quotes/viewQuotes')
);
const ProhibitedGoods = Loadable( () => import('../footerFiles/prohibitedGoods')
);
const CustomObligations = Loadable( () => import('../footerFiles/customObligations')
);
const PrepareShipment = Loadable( () => import('../footerFiles/prepareShipment')
);
const WhyUseEasyship = Loadable( () => import('../footerFiles/whyUseEasyShip')
);
const ProhibitedGoodsFrench = Loadable( () => import('../footerFiles/prohibitedGoodsFrench')
);
const TermsAndConditions = Loadable( () => import('../../containers/Terms&Condition/index')
);
const ContactUs = Loadable( () => import('../../containers/contactUs/index')
);
const OpenAccount = Loadable( () => import('../easyshipPro/openAccount')
);
const SendReferral = Loadable( () => import('../easyshipPro/sendReferral')
);
const Success = Loadable( () => import('../paymentResponse/success')
);
const Failed = Loadable( () => import('../paymentResponse/failed')
);
const Payment = Loadable( () => import('../payment/index')
);
const ShipmentHistory = Loadable( () => import('../shippingHistory/index')
);
const ViewHistory = Loadable( () => import('../shippingHistory/viewHistory')
);
const TransportNational = Loadable( () => import('../Carriers/national/transportNational')
);

const CourierService = Loadable( () => import('../Carriers/national/courierService')
);
const EuropeShipment = Loadable( () => import('../Carriers/interNational/europeShipment')
);
const NorthAmericaShipment = Loadable( () => import('../Carriers/interNational/northAmericaShipment')
);
const AfricaShipment = Loadable( () => import('../Carriers/interNational/africaShipment')
);
const AsiaShipment = Loadable( () => import('../Carriers/interNational/asiaShipment')
);
const SouthAmericaShipment = Loadable( () => import('../Carriers/interNational/southAmericaShipment')
);
const OceaniaShipment = Loadable( () => import('../Carriers/interNational/oceaniaShipment')
);
const ForgotPassword = Loadable( () => import('../forgotPassword/index')
);
const terms = Loadable( () => import('../../containers/termsForOnlineSales/index')
);
const DHLPage = Loadable( () => import('../../containers/Carriers/DHL/index')
);
const FedexPage = Loadable( () => import('../../containers/Carriers/fedex/index')
);
const UpsPage = Loadable( () => import('../../containers/Carriers/UPS/index')
);
const AramexPage = Loadable( () => import('../../containers/Carriers/Aramex/index')
);
const DHLTrackingPage = Loadable( () => import('../Carriers/Tracking')
);
const RequestPrices = Loadable( () => import('../easyshipPro/requsetPrices')
);
const AddressBook = Loadable( () => import('../../containers/addressBook')
);
const paymentSelect = Loadable( () => import('../../containers/order/selectPayment')
);
const SaleTerms = Loadable( () => import('../../containers/order/terms')
);
const PaymentMethod = Loadable( () => import('../../containers/paymentMethod')
);
const PendingShipments = Loadable( () => import('../../containers/shippingHistory/pendingShipments')
);
const CancelShipments = Loadable( () => import('../../containers/shippingHistory/cancelShipments')
);
const ErrorPage = Loadable( () => import('../../containers/fourZeroFour')
);
const PrivacyPolicy = Loadable( () => import('../footerFiles/privacyPolicy')
);
const AboutUs = Loadable( () => import('../footerFiles/aboutUs')
);

const App = () => (<Analytics id="UA-182650687-1" debug>
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/order" component={Order} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/updateProfile"
            render={() => {
                return Request.isLoggedIn() ? <UpdateProfile /> : <Redirect to="/" />
            }}
        />
        <Route exact path="/quoteList"
            render={() => {
                return Request.isLoggedIn() ? <QuoteList /> : <Redirect to="/" />
            }}
        />
        <Route exact path="/addressBook" component={AddressBook} />
        <Route exact path="/prohibitedGoods" component={ProhibitedGoods} />
        <Route exact path="/customObligations" component={CustomObligations} />
        <Route exact path="/prepareShipment" component={PrepareShipment} />
        <Route exact path="/openAccount" component={OpenAccount} />
        <Route exact path="/sendReferral" component={SendReferral} />
        <Route exact path="/whyUseEasyship" component={WhyUseEasyship} />
        <Route exact path="/prohibitedGoodsFrench" component={ProhibitedGoodsFrench} />
        <Route exact path="/termsAndConditions" component={TermsAndConditions} />
        <Route exact path="/SaleTerms" component={SaleTerms} />
        <Route exact path="/contactUs" component={ContactUs} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/failed" component={Failed} />
        <Route exact path="/paymentSelect" component={paymentSelect} />
        <Route exact path="/viewQuote"

            render={() => {
                return Request.isLoggedIn() ? <ViewQuote /> : <Redirect to="/" />
            }}

        />
        <Route exact path="/payment"

            render={() => {
                return Request.isLoggedIn() ? <Payment /> : <Redirect to="/" />
            }}

        />
        <Route exact path="/shipmentHistory"
            render={() => {
                return Request.isLoggedIn() ? <ShipmentHistory /> : <Redirect to="/" />
            }}
        />
        <Route exact path="/viewHistory"
            render={() => {
                return Request.isLoggedIn() ? <ViewHistory /> : <Redirect to="/" />
            }}
        />

        <Route exact path="/transportNational" component={TransportNational} />

        <Route exact path="/courierService" component={CourierService} />
        <Route exact path="/europeShipment" component={EuropeShipment} />
        <Route exact path="/northAmericaShipment" component={NorthAmericaShipment} />
        <Route exact path="/africaShipment" component={AfricaShipment} />
        <Route exact path="/asiaShipment" component={AsiaShipment} />
        <Route exact path="/southAmericaShipment" component={SouthAmericaShipment} />
        <Route exact path="/oceaniaShipment" component={OceaniaShipment} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/terms" component={terms} />
        <Route exact path="/dhl" component={DHLPage} />
        <Route exact path="/fedex" component={FedexPage} />
        <Route exact path="/ups" component={UpsPage} />
        <Route exact path="/aramex" component={AramexPage} />
        <Route exact path="/tracking" component={DHLTrackingPage} />
        <Route exact path="/requestPrices" component={RequestPrices} />
        <Route exact path="/paymentMethod" component={PaymentMethod} />
        <Route exact path="/pendingShipments" component={PendingShipments} />
        <Route exact path="/cancelShipments" component={CancelShipments} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/aboutUs" component={AboutUs} />

        <Route component={ErrorPage} />
    </Switch>
</Analytics>
)

export default App
