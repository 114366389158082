const transEN = {
    'Ship a Document': 'Ship a Document',
    'Ship a Parcel': 'Ship a Parcel',
    'Ship a Palette': 'Ship a Palette',
    'Freight Shipping': 'Freight Shipping',
    'Courier Services': 'Courier Services',
    'DHL': 'DHL',
    'UPS': 'UPS',
    'FedEx': 'FedEx',
    'Aramax': 'Aramax',
    'Support': 'Support',
    'EasyShip PRO': 'EASYSHIP PRO',
    'Express Delivery': 'Express Delivery',
    'Useful Tools': 'Useful Tools',
    'Express Air Transport': 'Express Air Transport',
    'Parcel tracking': 'Parcel tracking',
    'Carriers': 'CARRIERS',
    'International': 'INTERNATIONAL',
    'Domestic': 'DOMESTIC',
    'National Transport': 'Domestic Transportation',
    'Courier Service': 'Courier Service',
    'Shipments to Europe': 'Shipments to Europe',
    'Shipments to North America': 'Shipments to North America',
    'Shipments to Africa': 'Shipments to Africa',
    'Shipments to Asia': 'Shipments to Asia',
    'Shipments to South America': 'Shipments to South America',
    'Shipments to Oceania': 'Shipments to Oceania',
    'TrackingNumber': 'Tracking Number',
    'ShipmentType': 'ShipmentType',
    'Tracking': 'Track Now',
    'Track': 'Track',
    'Add Address': 'Add Address',
    'Edit Address': 'Edit Address',
    'Address Type': 'Address Type',
    'Billing': 'Billing',
    'Shipping': 'Shipping',
    'Delete': 'Delete',
    'Cancel': 'Cancel'
}

const transFR = {

    'Ship a Document': 'Envoi de Document Express',
    'Ship a Parcel': 'Envoi de Colis Express',
    'Ship a Palette': 'Envoi de Palette Express',
    'Freight Shipping': 'Envoi en Fret Aérien',
    'Courier Services': 'Service Coursier',
    'DHL': 'DHL',
    'UPS': 'UPS',
    'FedEx': 'FedEx',
    'Aramax': 'Aamax',
    'Support': 'Support',
    'EasyShip PRO': 'EASYSHIP PRO',
    'Express Delivery': 'Envoi en Express',
    'Useful Tools': 'Outils Utiles',
    'Express Air Transport': 'Transport Aérien Express',
    'Maritime transport': 'Transport Maritime',
    'Truck transport': 'Transport Routier',
    'Parcel tracking': 'Suivi de colis',
    'Carriers': 'TRANSPORTEURS',
    'International': 'INTERNATIONAL',
    'Domestic': 'NATIONAL',
    'National Transport': 'Transport National',
    'Courier Service': 'Service Coursier',
    'Shipments to Europe': 'Envois en Europe',
    'Shipments to North America': 'Envois en Amérique du Nord',
    'Shipments to Africa': 'Envois en Afrique',
    'Shipments to Asia': 'Envois en Asie',
    'Shipments to South America': 'Envois en Amérique du Sud',
    'Shipments to Oceania': 'Envois en Océanie',
    'TrackingNumber': 'Numéro de suivi',
    'ShipmentType': "Type d'expédition",
    'Tracking': 'Suivre maintenant',
    'Track': 'Suivre',
    'Add Address': "Ajoutez l'adresse",
    'Edit Address': "Modifier l'adresse",
    'Address Type': "Type d'adresse",
    'Billing': 'Facturation',
   // 'Shipping': 'livraison',
    'Delete': 'Supprimer',
    'Cancel': 'Annuler',
}

export default {
    en: transEN,
    fr: transFR
}
