const transEN = {
    'CUSTOMS': 'CUSTOMS OBLIGATIONS',
    'Customs, taxes and documents': 'Customs, taxes and documents to provide: what should I know before sending abroad?',
    'Documents to provide, customs formalities': 'Documents to provide, customs formalities',
    'In some cases, a document must accompany your shipments': 'In some cases, a document must accompany your shipments',
    'Your shipments to': 'Your shipments to or from abroad must be accompanied by a number of mandatory documents. Here is the list:',
    'Export': 'Export',
    'Formalities': 'Formalities',
    'For a parcel with no commercial value': 'For a parcel with no commercial value (Example: Gift, Personal items, Sample, return merchandise)',
    'Attach a commercial invoice.': 'Attach a commercial invoice.',
    'For a good or an object intended for sale': 'For a good or an object intended for sale or with high commercial value, or requesting a special authorization.',
    'Customs requires an export declaration.': 'Customs requires an export declaration. You must therefore plan to complete a power of attorney. Ask Easyship for details and we\'ll take care of the rest.',
    'Import': 'Import',
    'For a worthless document': 'For a worthless document',
    'There are no customs formalities': 'There are no customs formalities',
    'Minimum value packages': 'Minimum value packages',
    'There are no customs formalities *': 'There are no customs formalities *',
    'Parcels with a value exceeding 1200 DH': 'Parcels with a value exceeding 1200 DH',
    'Import declaration must be provided': 'Import declaration must be provided',
    'Customs reserves the right': '* Customs reserves the right to request an inspection or declaration at any time. Customs declaration, Taxes and Customs Duties are excluded from the Easyship service.',

}

const transFR = {
    'CUSTOMS': 'OBLIGATIONS DOUANIÈRES',
    'Customs, taxes and documents': 'Douanes, taxes et documents à fournir : que dois-je savoir avant un envoi à l’étranger ?',
    'Documents to provide, customs formalities': 'Documents à fournir, formalités douanières',
    'In some cases, a document must accompany your shipments': 'Dans certains cas, un document doit accompagner vos envois',
    'Your shipments to': 'Vos envois vers l’étranger ou en provenance de l’étranger doivent être accompagnés d’un certain nombre de documents obligatoires. En voici la liste :',
    'Export': 'Export',
    'Formalities': 'Formalités',
    'For a parcel with no commercial value': 'Pour un colis sans valeur commerciale (Exemple :Cadeau, Objets personnels, Echantillon, retour marchandise)',
    'Attach a commercial invoice.': 'Joindre obligatoirement une facture commerciale.',
    'For a good or an object intended for sale': 'Pour un bien ou un objet destiné à la vente ou à haute valeur commerciale, ou demandant une autorisation spéciale.',
    'Customs requires an export declaration.': 'Les douanes exigent une déclaration d\'exportation. Vous devez donc prévoir de compléter une procuration. Demandez les détails à Easyship et on s’occupe du reste.',
    'Import': 'Import',
    'For a worthless document': 'Pour un document sans valeur',
    'There are no customs formalities': 'Il n’y a pas de formalités de douane',
    'Minimum value packages': 'Colis à valeur minimale',
    'There are no customs formalities *': 'Il n’y a pas de formalités de douane*',
    'Parcels with a value exceeding 1200 DH': 'Colis à valeur dépassant 1200 DH\t',
    'Import declaration must be provided': 'Il faut prévoir une déclaration d’import',
    'Customs reserves the right': '*La douane se réserve le droit de demander une inspection ou déclaration à tout moment. Déclaration douanière, Taxes et Droits de Douane sont exclus de la prestation Easyship.',


}

export default {
    en: transEN,
    fr: transFR
}
