import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import QuotePages from './pages/quotepages'
import WhyUse from './pages/whyUse'
import customObligations from './pages/customObligation'
import prepareShipment from './pages/prepareShipment'
import openAccount from './pages/openAccount'
import sendRefrral from './pages/sendRefrral'
import prohibitedGoods from './pages/prohibitedGoods'
import menu from './pages/menu'
import termsCondition from './pages/termsCondition'
import contactUs from './pages/contactUs'
import offers from './pages/offers'
import transportNational from './pages/transportNational'
import europeShipment from './pages/europeShipment'
import northAmericaShipment from './pages/northAmericaShipment'
import africaShipment from './pages/africaShipment'
import asiaShipment from './pages/asiaShipment'
import southAmericaShipment from './pages/southAmericaShipment'
import oceaniaShipment from './pages/oceaniaShipment'
import courierService from './pages/courierService'
import order from './pages/order'
import success from './pages/shipmentSuccess'
import dhlPage from './pages/dhlPage'
import fedexPage from './pages/fedexPage'
import upsPage from './pages/upsPage'
import aramexPage from './pages/aramex'
import requestRate from './pages/requestPrices'
import notification from './pages/notification'
import paymentMethod from './pages/paymentMethod'
import privacyPolicy from './pages/privacyPolicy'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            ...QuotePages.en,
            ...WhyUse.en,
            ...customObligations.en,
            ...prepareShipment.en,
            ...openAccount.en,
            ...sendRefrral.en,
            ...prohibitedGoods.en,
            ...menu.en,
            ...termsCondition.en,
            ...contactUs.en,
            ...offers.en,
            ...transportNational.en,
            ...europeShipment.en,
            ...northAmericaShipment.en,
            ...africaShipment.en,
            ...asiaShipment.en,
            ...southAmericaShipment.en,
            ...oceaniaShipment.en,
            ...courierService.en,
            ...order.en,
            ...success.en,
            ...dhlPage.en,
            ...fedexPage.en,
            ...upsPage.en,
            ...aramexPage.en,
            ...requestRate.en,
            ...notification.en,
            ...paymentMethod.en,
            ...privacyPolicy.en
        }
    },
    fr: {
        translation: {
            ...QuotePages.fr,
            ...WhyUse.fr,
            ...customObligations.fr,
            ...prepareShipment.fr,
            ...openAccount.fr,
            ...sendRefrral.fr,
            ...prohibitedGoods.fr,
            ...menu.fr,
            ...termsCondition.fr,
            ...contactUs.fr,
            ...offers.fr,
            ...transportNational.fr,
            ...europeShipment.fr,
            ...northAmericaShipment.fr,
            ...africaShipment.fr,
            ...asiaShipment.fr,
            ...southAmericaShipment.fr,
            ...oceaniaShipment.fr,
            ...courierService.fr,
            ...order.fr,
            ...success.fr,
            ...dhlPage.fr,
            ...fedexPage.fr,
            ...upsPage.fr,
            ...aramexPage.fr,
            ...requestRate.fr,
            ...notification.fr,
            ...paymentMethod.fr,
            ...privacyPolicy.fr
        }
    }
}

i18n
    .use(initReactI18next) // passes i18n doSqueegee Screen Printingwn to react-i18next
    .init({
        debug: true,
        resources,
        lng: 'fr',
        keySeparator: true, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n
