const transEN = {
    'Fill Pickup City and Destination City': 'Fill Pickup City and Destination City',
    'Registration error!' : 'Registration error!',
    'Please agree the terms and conditions.' : 'Please agree the terms and conditions.',
    'Please Check this box if you want to proceed': 'Please Check this box if you want to proceed',
    "Login First": 'Login First',
    'Minimum insurance 200 MAD':'Minimum insurance 200 MAD'
}

const transFR = {
    'Fill Pickup City and Destination City': 'Remplissez la ville de ramassage et la ville de destination',
    'Registration error!' : "Erreur d'enregistrement!",
    'Please agree the terms and conditions.' : 'Veuillez accepter les termes et conditions.',
    'Please Check this box if you want to proceed': 'Veuillez cocher cette case si vous souhaitez continuer',
    "Login First": "S'enregistrer d'abord",
    'Minimum insurance 200 MAD':'Assurance minimum 200 MAD'
}

export default {
    en: transEN,
    fr: transFR
}
