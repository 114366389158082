const transEN = {
    'Sender': 'Sender',
    'Full Name': 'Full Name',
    'Social Reason': 'Social Reason',
    'Please input your phone number!': 'Please input your phone number!',
    'Phone': 'Phone',
    'The input is not valid E-mail!': 'The input is not valid E-mail!',
    'Please input your E-mail!': 'Please input your E-mail!',
    'E-mail': 'E-mail',
    'Complete Address with Street': 'Complete Address with Street',
    'Other information to help the collection driver': 'Other information to help the collection driver',
    'Add additional information': 'Add additional information',
    'billing address': 'Billing address different than shipping address ?',
    'Gift': 'Free of charge - Gift',
    'Document': 'Document',
    'Sample': 'Sample',
    'Commercial': 'Commercial',

    'Return and Repair': 'Subject to return',
    'Personal Effects': 'Personal Effects',
    'new Field': 'new Field',
    'Add a address line': 'Add a address line',
    'Receiver': 'Receiver',
    'Remember me': 'Remember me',
    'Shipment Reason': 'Shipment Reason',
    'Content': 'Content',
    'Custom Obligations': 'Custom Obligations',

    'Documents and taxes': 'Documents and taxes may be requested. Please refer to our section International shipping\n' +
    '                                So that your shipment is carried in the correct conditions, complete the following\n' +
    '                                fields',

    'Description': 'Description',
    'Country Name': 'Country Name',
    'Unit Price': 'Unit Price',
    'Currency': 'Currency',
    'Total': 'Total',

    'You have selected transport': 'You have selected transport without insurance. We recommend Ad Valorem insurance to\n' +
    '                                benefit from a claim in the event of a claim.',
    'Declaration': 'Declaration',
    'Declared Value': 'Declared Value',
    'insureAtReal1': 'I want to insure my package at its real value ',
    'insureAtReal2': 'and I accept the insurance conditions',
    'All Claims must be': 'User should consult T & Cs for carrier insurance details.',
    'Select Country': 'Select Country',

    'collectionChoosen': 'Schedule a pickup (subject to carrier availability)',
    'Collection': 'Collection',
    'Pickup Date': 'Pickup Date',
    'TimePicker': 'TimePicker',
    'I drop my parcels off.': 'I drop my parcels off.',
    'Opening Time': 'Opening Time',
    'Closing Time': 'Closing Time',
    'Information': 'Important!',
    'I declare that my shipment': 'I declare that my shipment does not contain Prohibited Goods, and I am solely responsible for it content.',
    'CIN Number': 'My CIN Number is:',
    // 'prohibited goods': 'See the list of prohibited goods',
    'acceptGeneralConditions': 'I accept Sales General Conditions',
    'viewGeneralConditions': 'View Sales General Conditions',
    'Continue to Payment': 'Continue to Payment',
    'SHIPPING DETAILS': 'DETAILS',
    'PACKAGE DETAILS': 'PACKAGE DETAILS',
    'PICKUP DATE': 'PICKUP DATE',
    'Your Offer': 'Your Offer',
    'TOTAL PAYABLE AMOUNT': 'TOTAL PAYABLE AMOUNT',
    'Save My Order': 'Save My Order',
    'Please Fill All Required Fields': 'Please Fill All Required Fields',
    'Error Saving': 'Error Saving',
    'Details Added Successfully': 'Details Added Successfully',
    'Offer Saved Successfully': 'Offer Saved Successfully',
    'Your Shipment': 'Your Shipment',
    'Shipment Characteristics': 'Shipment Characteristics',
    'Notify Recipient by Email': 'Notify Recipient by Email',
    'Pickup Information': 'Pickup Details',
    'Package': 'Package',
    'ofVariable': 'of',
    // 'Pallet': 'Pallet',
    // 'Parcel': 'Parcel'
    'Close Time should be greater than Open Time': 'Close Time should be greater than Open Time',
    'Open Time should be less than Close Time': 'Open Time should be less than Close Time',
    'Save and Continue': 'Save and Continue',
    'Save for Later': 'Save for Later',
    'Book Later.': 'Schedule pickup later.',
    'Download Invoice': 'Download Invoice',
    'infoPara1': 'Inadequacy of declared weight / volume: The price invoiced by Easyship to the Customer depends on the number, weight and dimensions declared by the Customer at the time of the Order.',
    'infoPara2': 'The customer is solely responsible for the information he communicates to Easyship at the time of the Order, essential information which alone makes it possible to determine the price of the order.',
    'infoPara3': 'It is therefore essential that the Customer enter the exact dimensions and weight of each package.<1> If the Goods are found to have a weight or volume different from those declared by the Customer at the time of the Order, the shipment may be subject to late delivery, withholding or return to the Sender.</1>',
    'infoPara4': 'In addition, depending on the General Conditions of Sale of the carrier chosen by the Customer, any mismatch between the information provided at the time of the Order by the Customer (weight, dimension, number of packages) may give rise to the invoicing of transport costs. additional resulting from this inadequacy, which the Customer expressly declares to accept by validating the Order.',
    'infoPara5': 'In addition, the Customer is informed that depending on the General Conditions of Sale of the chosen carrier, the administrative management of this mismatch between the information that he will have provided during the Order and the reality of the Goods may also give rise, in plus, the invoicing of additional service charges for administrative management.',
    'infoPara6': 'These costs vary from one carrier to another, the Customer undertakes to consult the General Conditions of Sale of the carrier he has chosen in order to know the terms, amount and / or method of calculating the additional billing. which will be charged to him in the event of a bad declaration on his part concerning the nature of the Goods.',
    'infoPara7': 'Your package must be properly packaged, measured and weighed. The carriers reserve the right to verify the dimensions and weight of each package, which could result in possible costs and additional costs. A defective packaging will deprive you of any recourse in the event of deterioration. Only the responsibility of the carrier can be engaged in case of bad or non-performance during the delivery of the package.',
}

const transFR = {

    'Sender': 'Expéditeur',
    'Full Name': 'Nom et Prénom',
    'Social Reason': 'Raison Sociale',
    'Please input your phone number!': 'Veuillez saisir votre numéro de téléphone!',
    'Phone': 'Tél',
    'The input is not valid E-mail!': "L'entrée n'est pas valide E-mail!",
    'Please input your E-mail!': 'Veuillez saisir votre e-mail!',
    'E-mail': 'E-mail',
    'Complete Address with Street': 'Adresse',
    'Other information to help the collection driver': 'Other information to help the collection driver',
    'Add additional information': 'Ajouter des informations supplémentaires',
    'billing address': "Adresse de facturation différente de l'adresse de livraison?",
    'Gift': 'Offert Gratuitement - Cadeau',
    'Document': 'Document',
    'Sample': 'Echantillon',
    'Commercial': 'Commercial',
    'Return and Repair': 'Sous réserve de retour\n',
    'Personal Effects': 'Effets Personnels',
    'new Field': 'new Field',
    'Add a address line': 'Ajouter votre adresse',
    'Receiver': 'Destinataire',
    'Remember me': 'Remember me',
    'Shipment Reason': 'Raison de l\'expédition',
    'Content': 'Nature du contenu',
    'Custom Obligations': 'Obligations Douanières',

    'Documents and taxes': 'Documents et taxes peuvent être demandés. Veuillez vous référer à notre section Expédition internationale Pour que votre envoi soit transporté dans les bonnes conditions, remplissez les champs suivants',

    'Description': 'La description',
    'Country Name': 'Nom du pays',
    'Unit Price': 'Prix ​​unitaire',
    'Currency': 'Devise',
    'Total': 'Totale',

    'You have selected transport': 'Vous avez choisi le transport sans assurance. Nous recommandons l\'assurance Ad Valorem pour bénéficier d\'un sinistre en cas de sinistre.',
    'Declaration': 'Declaration',
    'Declared Value': 'Valeur déclarée',
    'insureAtReal1': 'Je souhaite assurer mon colis à sa valeur réelle ',
    'insureAtReal2': 'et j\'accepte les conditions d\'assurance',
    'All Claims must be': 'L\'utilisateur doit consulter les CGV pour les détails de l\'assurance des transporteurs.',
    'Select Country': 'Select Country',
    'collectionChoosen': 'Planifier un enlèvement (sous réserve de la disponibilité du transporteur)',
    'Collection': 'Collection',
    'Pickup Date': 'Date de ramassage',
    'TimePicker': 'TimePicker',
    'I drop my parcels off.': 'Je dépose mes colis.',
    'Opening Time': 'Horaire d\'ouverture',
    'Closing Time': 'Heure de fermeture',
    'Information': 'Important!',
    'I declare that my shipment': 'Je déclare sur l\'honneur que le contenu de mon colis ne contiens pas les produits interdits ou dangereux. Je suis seul résponsable du contenu de mon colis.',
    'CIN Number': 'Le numéro de mon CIN:',

    // 'prohibited goods': 'See the list of prohibited goods',
    'acceptGeneralConditions': 'J\'accepte toutes les conditions Générales de vente',
    'viewGeneralConditions': 'Voir les conditions générales de vente',
    'Continue to Payment': 'Continuer au Paiement',
    'SHIPPING DETAILS': 'Détails',
    'PACKAGE DETAILS': 'Détails du Colis',
    'PICKUP DATE': 'Date d\'expédition',
    'Your Offer': 'Votre Offre',

    'TOTAL PAYABLE AMOUNT': 'Montant à Payer',
    'Save My Order': 'Sauvegarder ma commande',
    'Please Fill All Required Fields': 'Veuillez remplir tous les champs obligatoires',
    'Error Saving': "Erreur lors de l'enregistrement",
    'Details Added Successfully': 'Détails ajoutés avec succès',
    'Offer Saved Successfully': 'Offre enregistrée avec succès',
    'Your Shipment': 'Votre Expédition',
    'Shipment Characteristics': "Caractéristiques D\'expédition",
    'Notify Recipient by Email': 'Notifier le destinataire par e-mail',
    'Pickup Information': 'Détails d\'enlèvement',
    'Package': 'Colis',
    'ofVariable': 'de',
    'Close Time should be greater than Open Time': "L'heure de fermeture doit être supérieure à l'heure d'ouverture",
    'Open Time should be less than Close Time': "Le temps d'ouverture doit être inférieur au temps de fermeture",
    'Save and Continue': 'Sauvegarder et continuer',
    'Save for Later': 'Garder pour plus tard',
    'Book Later.': 'Planifier le ramassage ultérieurement.',
    'Download Invoice': 'Télécharger la facture',
    'infoPara1': 'Inadéquation du poids/volume déclaré : Le prix facturé par Easyship au Client dépend du nombre, du poids et des dimensions déclarées de la Marchandise par le Client au moment de la Commande.',
    'infoPara2': 'Le client est seul responsable des informations qu’il communique à Easyship au moment de la Commande, informations essentielles qui seules permettent de déterminer le prix de la commande.',
    'infoPara3': 'Il est donc indispensable que le Client introduise les dimensions et le poids exacts de chaque colis. <1>Si la Marchandise s’avère avoir un poids ou un volume différent de ceux déclarés par le Client au moment de la Commande, l’expédition pourra subir un retard de livraison, une retenue ou un retour à l’Expéditeur.</1>',
    'infoPara4': 'En outre, en fonction des Conditions Générales de Vente du transporteur choisi par le Client, toute inadéquation entre les informations fournies au moment de la Commande par le Client (poids, dimension, nombre de colis) pourra donner lieu à la facturation des coûts de transport supplémentaires résultant de cette inadéquation, ce que le Client déclare expressément accepter en validant la Commande.',
    'infoPara5': 'En plus, le Client est informé qu’en fonction des Conditions Générales de Vente du transporteur choisi, la gestion administrative de cette inadéquation entre les informations qu’il aura fournies lors de la Commande et la réalité de la Marchandise pourra donner également lieu, en sus, à la facturation de frais de services supplémentaires pour gestion administrative.',
    'infoPara6': 'Ces frais variant d’un transporteur à l’autre, le Client s’engage à consulter les Conditions Générales de Vente du transporteur qu’il aura choisi afin de connaître les modalités, le montant et/ou le mode de calcul de la facturation supplémentaire qui lui sera imputé en cas de mauvaise déclaration de sa part sur la nature de la Marchandise.',
    'infoPara7': 'Votre colis doit être correctement emballé, mesuré et pesé. Les transporteurs se réservent le droit de vérifier les dimensions et le poids de chaque colis, ce qui pourrait entraîner des frais éventuels et des frais supplémentaires. Un défaut d\'emballage vous privera de tout recours en cas de détérioration. Seule la responsabilité du transporteur pourra être engagée en cas de mauvaise ou d\'inexécution lors de la livraison du colis',

}

export default {
    en: transEN,
    fr: transFR
}
