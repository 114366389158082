const transEN = {
    'Contact Us': 'Contact Us',
    'ContactHeading': 'Need to write to us? If you have questions, comments, a partnership request or simply for a suggestion, nothing could be simpler!',
    'addressL1': '315 Bd Ambassador Ben Aicha',
    'addressL2': 'Office 12 Roches Noires',
    'addressL3': 'Casablanca - Morocco',
    'addressL4': '48 Rue de la liberté',
    'addressL5': 'Bureau 21 Gueliz',
    'addressL6': 'Marrakech - Morocco',
    'contactName': 'Contact name',
    'contactEmail': 'E-mail',
    'contactTelephone': 'Telephone',
    'contactSubject': 'Topic',
    'contactMessage': 'Message',
    'toSend': 'To send',
    'Please insert your name.!': 'Please insert your name.!',
    'Please insert your Email.!': 'Please insert your Email.!',
    'Please insert your phone.!': 'Please insert your phone.!',
    'Please insert your Subject.!': 'Please insert your Subject.!',
    'Please insert your message.!': 'Please insert your message.!'
}

const transFR = {
    'Contact Us': 'Nous Contacter',
    'ContactHeading': 'Besoin de nous écrire ? Si vous avez des questions, des remarques, une demande de\n' +
    '                            partenariat ou\n' +
    '                            simplement pour une suggestion, rien de plus simple !',
    'addressL1': '315 Bd Ambassadeur Ben Aicha',
    'addressL2': 'Bureau 12 Roches Noires',
    'addressL3': 'Casablanca - Maroc',
    'addressL4': '48 Rue de la liberté',
    'addressL5': 'Bureau 21 Gueliz',
    'addressL6': 'Marrakech - Maroc',
    'contactName': 'Nom',
    'contactEmail': 'Email',
    'contactTelephone': 'Téléphone',
    'contactSubject': 'Sujet',
    'contactMessage': 'Message',
    'toSend': 'Envoyer',
    'Please insert your name.!': 'Veuillez Insérer votre nom.!',
    'Please insert your Email.!': 'Veuillez Insérer votre Email.!',
    'Please insert your phone.!': 'Veuillez Insérer votre Téléphone.!',
    'Please insert your Subject.!': 'Veuillez Insérer votre Sujet.!',
    'Please insert your message.!': 'Veuillez Insérer votre Message.!'


}

export default {
    en: transEN,
    fr: transFR
}
