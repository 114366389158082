const transEN = {

    //////PROHIBITED GOODS
    'PROHIBITED GOODS': 'PROHIBITED GOODS',
    'Below is a list of forbidden': 'Below is a list of forbidden or controlled goods. We advise you to check that your items are not on this list before sending.',
    'Non-transportable Goods': 'Non-transportable Goods :',
    'Dangerous, inflammable or corrosive products': 'Dangerous, inflammable or corrosive products',
    'Compressed air products (for example aerosols, gas bottles, extinguishers)': 'Compressed air products (for example aerosols, gas bottles, extinguishers)',
    'Perfumes': 'Perfumes',
    'Lithium-ion battery': 'Lithium-ion battery except if it\'s included in the equipment (mobile phone, laptop, camera, etc. CAREFUL: only one item containing a battery is allowed per consignment). Products including a powerful battery are forbidden (electric bicycles and wheels, electric motors, etc.).',
    'Electronic smoking devices': 'Electronic smoking devices',
    'Engine or gearbox not drained': 'Engine or gearbox not drained',
    'Household appliances': 'Household appliances',
    'Plasma TVs, and LCD screens over 32"': 'Plasma TVs, and LCD screens over 32"',
    'Cash, jewelry made of precious stones or metals': 'Cash, jewelry made of precious stones or metals',
    'Explosives': 'Explosives, fireworks and other incendiary or inflammable articles, firearms, war weapons, munitions and spare parts',
    'Money - all types even negotiable': 'Money - all types even negotiable instruments considered as cash, such as securities, shares, etc.',
    'Human bodies, organs or body parts': 'Human bodies, organs or body parts, human or animal embryos, incinerated or decomposed human remains, dangerous waste, including but not restricted to, used hypodermic needles and/or syringes or medical waste',
    'Perishable foodstuffs, plants and plant products': 'Perishable foodstuffs, plants and plant products',
    'Any other product with this kind of symbol': 'Any other product with this kind of symbol :',
    'Wet parcels, parcels that appear to be leaking or emit some kind of smell': 'Wet parcels, parcels that appear to be leaking or emit some kind of smell',
    'Products requiring a license or special authorization': 'Products requiring a license or special authorization for their transport, import or export (such as drugs, food and in particular alcoholic beverages)',
    'Articles which transport, import or export are forbidden by any law or regulation.': 'Articles which transport, import or export are forbidden by any law or regulation.',
    'Shipments which might damage or cause delay to equipment, staff or other parcels.': 'Shipments which might damage or cause delay to equipment, staff or other parcels.',
    'You should also contact the customs service': 'You should also contact the customs service in\n' +
    'the destination country. These types of goods\n' +
    'must be accompanied by official documents, such as licenses, certificates or permits to\n' + 'import or export.',
    'Goods banned from export': 'Goods banned from export',
    'Counterfeit goods': 'Counterfeit goods',
    'Goods of a pedophile nature': 'Goods of a pedophile nature',
    'asbestos and products containing it': 'asbestos and products containing it, except for those items covered by Article 7, of 24th December 1996',
    'Goods banned from import': 'Goods banned from import',
    'products containing dangerous substances, such as lead salts or nickel': 'products containing dangerous substances, such as lead salts or nickel',
    'plants, plant products such as bark, seeds, soil and': 'plants, plant products such as bark, seeds, soil and growing mediums, which are all prohibited from being brought into all the member states, as set out in Appendix lll of the 24th May 2006',
    'any animal feeds or food of animal origin': 'any animal feeds or food of animal origin which are prohibited under the current national or EC health regulations',
    'Cat or dog skins or furs and any products containing them': 'Cat or dog skins or furs and any products containing them',
}

const transFR = {

}

export default {
    en: transEN,
    fr: transFR
}
