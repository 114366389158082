const transEN = {
    'section1Heading': 'Bank Card',
    'section1Para1': 'Transactions are secured by CMI.',
    'section1Para2': 'Orders must be paid for in the “My Payments” section of your customer area.',
    'section1Para3': 'Validation time: Most of our services are automatically activated after payment.',
    'section2Heading': 'Bank Transfer / Deposit',
    'sectionCommonPara': 'Please make your transfer payable to: InXpress Morocco',
    'section2Para1': 'Bank: Banque Populaire',
    'section2Para2': 'R.I.B: 190 780 21211 0087461 000 7 34',
    'section2Para3': 'Validation deadline: Your payment will be validated the same day, if performed within working hours.',
    'sectionCommonPara1': 'Please send proof of payment to',
    'ouWhatsApp': 'or WhatsApp',
    'section3Para1': 'Cash payment on account N ° ……………',
    'section3Para2': 'The transaction costs are the responsibility of the customer.',
    'section3Para3': 'Validation deadline: Your payment will be validated the same day, if made within working hours.',
    'section4Para1': 'This method of payment is reserved for our international customers.',
    'section4Para2': 'Send the amount of your invoice in the name of <1>Rachid Tayach</1>',
    'section4Para3': 'Please send proof of payment to',
    'section4Para4': 'The transaction costs are the responsibility of the customer.',
    'section4Para5': 'Validation deadline: Your payment will be validated the same day, if made within working hours.',
    'section5Heading': 'Cash',
    'section5Para1': 'We accept payment by cash at any of our offices.',
    'section5Para2': 'InXpress Morocco',
    'section5Para3': '315 Bd Ambassador Ben Aicha Etg 1 Office 12 Roches Noires Casablanca',
    'section5Para4': '48 Rue de la Liberté Bureau 21 Gueliz Marrakech - Morocco',
    'section5Para5': 'Validation period: Your payment will be validated upon receipt of your payment.',
    'section6Heading': 'Cheque',
    'section6Para1': 'Please make your payment in the name of: <1>InXpress Morocco</1>',
    'section6Para2': 'Send or deposit the check to the following address:',
    'section6Para3': 'Validation period: Your payment will be validated upon receipt of your check.',
    'section5Para6': 'or payment into the company\'s bank account:',
    'section5Para7': 'R.I.B: 190 780 21211 0087461 000 7 34',
    'ouWhatsApp1': 'or by WhatsApp at:'
}

const transFR = {
    'section1Heading': 'Carte Bancaire',
    'section1Para1': 'Les transactions sont sécurisées par CMI.',
    'section1Para2': 'Les commandes doivent être payées sur la rubrique “Mes Paiements” de votre espace client.',
    'section1Para3': 'Délai de validation: La plupart de nos services sont automatiquement activés après le paiement.',
    'section2Heading': 'Virement / Versement Bancaire',
    'sectionCommonPara': 'Veuillez libeller votre virement au nom de: <1>InXpress Maroc</1>',
    'section2Para1': 'Banque: Banque Populaire',
    'section2Para2': 'R.I.B: 190 780 21211 0087461 000 7 34',
    'section2Para3': 'Délai de validation: Votre paiement sera validé le même jour, si effectué dans les heures de travail.',
    'sectionCommonPara1': 'Merci d\'envoyer le justificatif du paiement à',
    'ouWhatsApp': 'ou WhatsApp',
    'section3Para1': 'Versement en espèce sur le compte N° ……………',
    'section3Para2': 'Les frais de transaction sont à la charge du client.',
    'section3Para3': ' Délai de validation: Votre paiement sera validé le même jour, si effectué dans les\n' +
    '                                heures de\n' +
    '                                travail.',
    'section4Para1': 'Ce mode de paiement est réservé à nos clients internationaux.',
    'section4Para2': 'Envoyez le montant de votre facture au nom de <1>Rachid Tayach</1>',
    'section4Para3': 'Merci d\'envoyer le justificatif du paiement à',
    'section5Heading': 'Espèce',
    'section5Para1': 'Nous acceptons le paiement par espèces dans l’un de nos bureaux.',
    'section5Para2': 'InXpress Maroc',
    'section5Para3': '315 Bd Ambassadeur Ben Aicha Etg 1 Bureau 12 Roches Noires Casablanca',
    'section5Para4': '48 Rue de la liberté Bureau 21 Gueliz Marrakech - Maroc',
    'section5Para5': 'Délai de validation: Votre paiement sera validé à la réception de votre paiement.',
    'section6Heading': 'Chèque',
    'section6Para1': 'Veuillez libeller votre paiement au nom de: <1>InXpress Maroc</1>',
    'section6Para2': 'Envoyez ou déposez le chèque à l’adresse suivante:',
    'section6Para3': 'Délai de validation: Votre paiement sera validé à la réception de votre chèque.',
    'section5Para6': 'ou versement dans le compte bancaire de la société:',
    'section5Para7': 'R.I.B: 190 780 21211 0087461 000 7 34',
    'ouWhatsApp1': 'ou par WhatsApp au:'

}

export default {
    en: transEN,
    fr: transFR
}
