import axios from 'axios'

import {apiUrl, PaymentUrl} from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})

let getToken = () => {
    return ({'headers': {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
}

export const getAmountInCrr = (value, showCur, isString) => {

    if (!value) {
        value = 0
    }

    if (typeof value == 'string') {
        value = parseFloat(value)
    }

    let currencyAmount = JSON.parse(localStorage.getItem('currencyAmount'))
    let currencyName = localStorage.getItem('currencyName')

    let realValue
    if (currencyName === 'EUR') {
        realValue = currencyAmount.MAD_EUR
        return showCur ? `€${((value || 0) * realValue).toFixed(2)}` : ((value || 0) * realValue).toFixed(2)

    } else if (currencyName === 'USD') {
        realValue = currencyAmount.MAD_USD
        return showCur ? `$${((value || 0) * realValue).toFixed(2)}` : ((value || 0) * realValue).toFixed(2)

    } else {
        return showCur ? `MAD ${((value || 0)).toFixed(2)}` : ((value || 0)).toFixed(2)

    }

}

setInterval(() => {
    requestObj.getCurrency()
}, 10 * 60 * 1000)

class Request {

    error = (err) => {
        try {

            if (err.response.status === 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        } catch (e) {
        }
    }

    isLoggedIn = () => {
        let token = localStorage.getItem('token')
        if (token) {
            return true
        } else {
            return false
        }
    }

    constructor() {
        this.clearUserIfExpire()
        this.getCurrency()
    }

    clearUserIfExpire = async () => {

        let token = localStorage.getItem('token')

        if (token) {

            authAxios.post('/validateToken', {token})
                .then((d) => {

                    if (d && d.data && d.data.expire) {
                        localStorage.clear()
                        window.location.href = '/'

                    }
                })
                .catch((err) => {

                })
        }

    }

    login(data) {
        return new Promise((next, error) => {
            authAxios.post('/login', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    loadCountries(data) {
        return new Promise((next) => {
            authAxios
                .get('/loadCountries',{ params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addQuote(data) {
        return new Promise(next => {
            let user = localStorage.getItem('user')

            if (user) {
                user = JSON.parse(user)

                if (user && user.accountNumber) data.userAccountNo = user.accountNumber
            }
            authAxios
                .post('/quote', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getQuoteById(data) {
        return new Promise(next => {
            authAxios
                .get('/quote/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getQuoteByIdWithFilter(data) {
        return new Promise(next => {
            authAxios
                .get('/quote/' + data._id,{ params: {tracking: true}, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateQuote(data, id) {
        return new Promise(next => {

            let user = localStorage.getItem('user')

            if (user) {
                user = JSON.parse(user)
                if (user && user.accountNumber) data.userAccountNo = user.accountNumber
            }

            authAxios
                .put('/quote/', data, getToken())
                .then(d => {

                    //window.open(`${apiUrl}/pay/${id}`, "_self");

                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addUser(data) {
        return new Promise(next => {
            authAxios
                .post('/user', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getUser() {
        return new Promise(next => {
            authAxios
                .post('/profile', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getParcelById(data) {
        return new Promise(next => {
            authAxios
                .get('/parcelPrice/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllCity(data) {
        return new Promise((next) => {
            authAxios
                .get('/city', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    citySearch(data) {
        return new Promise(next => {
            authAxios
                .put('/citySearch', {data}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateProfile(data) {
        return new Promise(next => {
            authAxios
                .put('/profile', data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    getAllQuote(data) {
        return new Promise((next) => {
            authAxios
                .post('/myQuote', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    // addAccountDetails(data) {
    //     return new Promise(next => {
    //         authAxios
    //             .post('/addAccountEasyship', data)
    //             .then(d => {
    //                 next(d.data)
    //             })
    //             .catch(err => {
    //                 next({error: true, err})
    //                 this.error(err)
    //             })
    //     })
    // }

    downLoadZip(data) {
        return new Promise((next) => {
            authAxios
                .post('/downloadZip', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    fetchQuoteError(data) {
        return new Promise(next => {
            authAxios
                .post('/get/quoteErrors', data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    trackPackage(data) {
        return new Promise((next) => {
            authAxios
                .get('/trackPackage', {params: {...data}}, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addProAccountDetails(data) {
        return new Promise(next => {
            authAxios
                .post('/addEasyshipProAccount', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addRequestRateDetails(data) {
        return new Promise(next => {
            authAxios
                .post('/priceRequest', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateUserLanguage(data) {
        return new Promise(next => {
            authAxios
                .put('/updateUserByLang', data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    contactUsForEasyship(data) {
        return new Promise(next => {
            authAxios
                .post('/contactDetailsForEasyship', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    downLoadCommercial(data) {
        return new Promise(next => {
            authAxios
                .post('/printCommercial', data, getToken())
                .then(d => {
                    if (d.data) {
                        let linkValue = API_URL + `${d.data.pdfName}`
                        window.open(linkValue, 'download')
                    }
                    next('done')
                })
                .catch(err => {
                    this.error(err)
                })
        })
    }

    addAddress(_id, data) {
        return new Promise(next => {
            authAxios
                .put(`/userAddress/${_id}`, data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    editAddress(_id, data) {
        return new Promise(next => {
            authAxios
                .put(`/editUserAddress/${_id}`, data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    getAddresses(_id) {
        return new Promise(next => {
            authAxios
                .get(`/userAddress/${_id}`, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    deleteAddress(_id, data) {
        return new Promise(next => {
            authAxios
                .delete(`/userAddress/${_id}`, {params: data, ...getToken()})
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    createPickup(data) {
        return new Promise(next => {
            authAxios
                .post(`/pickup/create/new`, data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    downLoadZipForFedex(data) {
        return new Promise((next) => {
            authAxios
                .post('/downloadZipForFedex', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    voidShipment(data) {
        return new Promise(next => {
            authAxios
                .post(`/quote/void`, data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    downLoadLabelForAramex(data) {
        return new Promise((next) => {
            authAxios
                .post('/downloadLabelForAramex', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    sendOtp(data) {
        return new Promise(next => {
            authAxios
                .post('/sendOtp', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    changePassword(data) {
        return new Promise(next => {
            authAxios
                .post('/changePassword', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    validateCoupon(data) {
        return new Promise(next => {
            authAxios
                .post('/getActiveCoupon', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    incrementCoupon(data) {
        return new Promise(next => {
            authAxios
                .post('/incrementCoupon', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    decrementCoupon(data) {
        return new Promise(next => {
            authAxios
                .post('/decrementCoupon', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getCurrency(data) {
        return new Promise(next => {
            authAxios
                .get('/convertCurrency', getToken())
                .then(d => {
                    let {data} = d.data
                    localStorage.setItem('currencyAmount', JSON.stringify(data))
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getActiveCouponList(data) {
        return new Promise((next) => {
            authAxios
                .get('/getActiveCouponList', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    loadDomesticCities(data) {
        return new Promise((next) => {
            authAxios
                .get('/loadDomesticCity', { params: { ...data }, ...getToken() })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getCityById(data) {
        return new Promise(next => {
            authAxios
                .get('/city/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    processShipment(data) {
        return new Promise(next => {
            authAxios
                .get(`/pay/${data.ReturnOid}`, {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


}

const requestObj = new Request()
export default requestObj
