const transEN = {
    'transportHeading1': 'HOW TO SHIP A PARCEL INSIDE MOROCCO SAFE AND CHEAP WITH EASYSHIP',
    'transportPara1': 'To save money when sending your packages to Morocco, we recommend that you use our search engine to be able to compare several carriers and thus assess their prices and the quality of their services. On Easyship platform, you can access a comparison of prices and services of the most reliable transportation companies. Don\'t waste time, and discover the list of our national services.',
    'nationalServices': '',
    'transportHeading2': 'THE BEST PRICES AND SERVICES TO SEND YOUR PACKAGE ACROSS MOROCCO',
    'transportPara2': 'In order to ship your packages across Morocco, do not hesitate to search through the search engine above. In just a few clicks, you have access to a list of services from different carriers that matches your request, and you can choose the best price, or the best service according to your criteria (urgent dispatch, large parcel shipments, etc. ). If before choosing one of the services offered, you would like to know more about one of the carriers, you can consult our “Carriers” section. You will find descriptions of the various carriers there.',
    'emergencyDispatch': ' ',
    'largeParcel': ' ',
    'transportSubPara1': ' ',
    'Transporteurs': ' ',
    'transportSubPara2': ' ',
    'transportHeading3': 'A CONVENIENT SITE FOR SENDING PARCELS ACROSS MOROCCO',
    'transportPara3': 'The Easyship.ma site has been developed in such a way as to offer Internet users excellent ergonomics in accessing parcel delivery services in Morocco. To execute a shipment, you will only have to enter the weight and dimensions of the package and choose a shipment option among those offered by the portal. During the shipping of your parcel, you will be able to follow and control the good progress of its delivery.',
    'transportSubPara3': '',
    'transportHeading4': 'PACKAGING',
    'transportPara4': 'To avoid possible damages to goods during the journey to Casablanca, opt for fairly robust boxes. However, a simple cardboard box is not always enough to protect objects against relatively severe attacks. Hence the need to absorb shocks by padding the empty spaces between the goods and the inner walls of the carton with conventional paper or bubble wrap. Finally, seal the packaging with adhesive tape of the gaffer type at least 50mm wide.',
    'transportHeading5': 'Prohibited goods',
    'transportPara5': 'A number of goods are prohibited from being sent. These include documents, animals, weapons, ammunition, replica guns, air guns, stolen items and other illegal content. Before sending via Easyship, it is essential to check the contents of your packages. They must not contain prohibited items as this could result in the cancellation without notice of your shipment to Casablanca.',
    'transportHeading6': 'MOST POPULAR DESTINATIONS IN MOROCCO',
    'Envoi de colis à Casablanca': 'Shipping parcels to Casablanca',
    'Envoi de colis à Marrakech': 'Shipping parcels to Marrakech',
    'Envoi de colis à Rabat': 'Shipping parcels to Rabat',
    'Envoi de colis à Tanger': 'Shipping parcels to Tangier',
    'Envoi de colis à Fès': 'Shipping parcels to Fez',
    'Envoi de colis à Oujda': 'Shipping parcels to Oujda',
    'Envoi de colis à Agadir': 'Shipping parcels to Agadir',
    'Envoi à Casablanca': 'Shipping to Casablanca',
    'Envoi à Marrakech': 'Shipping to Marrakech',
    'Envoi à Rabat': 'Shipping to Rabat',
    'Envoi à Tanger': 'Shipping to Tangier',
    'Envoi à Fès': 'Shipping to Fez',
    'Envoi à Oujda': 'Shipping to Oujda',
    'Envoi à Agadir': 'Shipping to Agadir'

}

const transFR = {
    'transportHeading1': 'COMMENT ENVOYER UN COLIS A TRAVERS LE MAROC PAS CHER AVEC EASYSHIP',
    'transportPara1': 'Pour faire des économies lors de l’envoi de vos colis au Maroc, nous vous conseillons\n' +
    '                            d’utiliser notre moteur de recherche afin de pouvoir comparer plusieurs transporteurs et\n' +
    '                            ainsi évaluer leurs tarifs et la qualité de leurs services. Sur Easyship, vous pouvez\n' +
    '                            accéder à un comparatif de prix et de prestations des entreprises de transport les plus\n' +
    '                            fiables. Ne perdez pas de temps, et découvrez la liste de nos',
    'nationalServices': 'services nationaux.',
    'transportHeading2': 'LES MEILLEURS PRIX ET SERVICES POUR ENVOYER VOTRE COLIS A TRAVERS TOUT LE MAROC',
    'transportPara2': 'Afin d’expédier vos colis à travers le Maroc, n’hésitez pas à lancer une recherche à travers\n' +
    '                            le moteur de recherche ci-dessus. En quelques clics seulement, vous avez accès à une liste\n' +
    '                            de services de différents transporteurs qui correspond à votre demande, et vous pouvez\n' +
    '                            choisir le meilleur prix, ou le meilleur service en fonction de vos critères (',
    'emergencyDispatch': 'envoi en urgence ,',
    'largeParcel': 'envois de colis volumineux',
    'transportSubPara1': ', etc). Si avant de\n' +
    '                            choisir un\n' +
    '                            des services proposés,\n' +
    '                            vous souhaitez en savoir plus sur un des transporteurs, vous pouvez consulter notre\n' +
    '                            rubrique',
    'Transporteurs': '“Transporteurs“',
    'transportSubPara2': '. Vous y trouverez des fiches descriptives des différentes transporteurs.',
    'transportHeading3': 'UN SITE PRATIQUE POUR L’ENVOI DE COLIS A TRAVERS LE MAROC',
    'transportPara3': 'Le portail Easyship.ma a été élaboré de manière à offrir aux internautes\n' +
    '                            une excellente\n' +
    '                            ergonomie dans l’accès aux prestations d’envoi de colis au Maroc. Pour réaliser un envoi,\n' +
    '                            vous n’aurez qu’à entrer le poids et les dimensions du colis et choisir une option d’envoi\n' +
    '                            parmi celles proposées par le portail. Pendant le transport de votre colis, vous pourrez',
    'transportSubPara3': 'suivre et contrôler le bon déroulement de sa\n' +
    '                            livraison.',
    'transportHeading4': 'L’emballage',
    'transportPara4': 'Afin d’éviter les éventuelles dégradations des marchandises durant le trajet vers Casablanca, optez pour un carton assez robuste. Toutefois, un simple carton ne suffit pas toujours pour protéger les objets contre les agressions relativement importantes. D’où la nécessité d’amortir les chocs en rembourrant les espaces vides situés entre les marchandises et les parois intérieures du carton avec du papier classique ou du papier bulle. Fermez enfin l’emballage avec du ruban adhésif de type gaffer de 50mm de largeur au minimum.',
    'transportHeading5': 'Les marchandises interdites',
    'transportPara5': 'Un certain nombre de marchandises sont interdites d’envoi. Ce sont entre autres les documents, les animaux, les armes, les munitions, les répliques d’arme, les fusils à air comprimé, les objets volés et tout autre contenu illicite. Avant d’effectuer votre envoi via Easyship, il est essentiel de vérifier le contenu de vos paquets. Ils ne doivent pas contenir d’articles interdits car cela pourrait entrainer une annulation sans préavis de votre expédition vers Casablanca.',
    'transportHeading6': 'Destinations les plus populaires au Maroc',
    'Envoi de colis à Casablanca': 'Envoi de colis à Casablanca',
    'Envoi de colis à Marrakech': 'Envoi de colis à Marrakech',
    'Envoi de colis à Rabat': 'Envoi de colis à Rabat',
    'Envoi de colis à Tanger': 'Envoi de colis à Tanger',
    'Envoi de colis à Fès': 'Envoi de colis à Fès',
    'Envoi de colis à Oujda': 'Envoi de colis à Oujda',
    'Envoi de colis à Agadir': 'Envoi de colis à Agadir',
    'Envoi à Casablanca': 'Envoi à Casablanca',
    'Envoi à Marrakech': 'Envoi à Marrakech',
    'Envoi à Rabat': 'Envoi à Rabat',
    'Envoi à Tanger': 'Envoi à Tanger',
    'Envoi à Fès': 'Envoi à Fès',
    'Envoi à Oujda': 'Envoi à Oujda',
    'Envoi à Agadir': 'Envoi à Agadir'


}


export default {
    en: transEN,
    fr: transFR
}
