const transEN = {
    'europeHeading1': 'THE BEST WAY TO SHIP A PARCEL TO EUROPE QUICKLY AND MORE ECONOMICALLY WITH EASYSHIP',
    'europePara1': 'To ship your parcel to Europe on Easyship.ma, enter the weight of your parcel as well as its dimensions in the search engine above. Access the page where you can find the various services we offer, at very competitive prices, simply by clicking on “Compare Prices”, compare the offers of our partners and their prices, then make your choice. Depending on the carrier that will handle your shipment, you will be asked to print the shipping label. The "Carriers" section allows you to know the different carriers we work in collaboration with such as DHL, UPS, FedEx ... Easyship also ensures the delivery of your shipments in Europe, in complete transparency since at any time, you can track your parcel by clicking on ',
    'parcelTracking': '"Parcel Tracking"',
    'europeSubPara1': '',
    'europeTransporteurs': '',
    'europeSubPara3': '',
    'europeSubPara2': '',
    'europeSubPara4': '',
    'europePara2': '',
    'europeHeading2': 'TIPS FOR SHIPPING YOUR PACKAGE TO EUROPE',
    'europePara3': 'For all shipments to Europe from non-EU countries, simple forms must be completed. Do not worry, we take care of informing you of the procedures to follow and the documentation that you will need to provide depending on the country. Remember that it is necessary to include in the requested data, the telephone number of the person receiving the package; this helps to ensure that the package is delivered on the carrier\'s first pass. In some countries, we call the same person if the address is incomplete. This applies for France and Portugal for example.',
    'europePara4': 'You must also make sure to calculate the weight and dimensions of your package in order to be able to choose the type of services adapted to your package among the offers of our carriers.',
    'europePara5': 'Remember to put your item in a cardboard box and protect it properly when shipping to Europe (choose adequate packaging and material that absorbs impacts, such as bubble wrap, polystyrene, etc.). Make sure the package is properly sealed. If there is more than one item in the crate, pack each item separately, this will prevent items from getting damaged between them. The most delicate and heavy items will need good protection at the bottom of the crate, sides, and top of the carton.',
    'europePara6': 'Please take into account that shipments to European countries go through customs. When your package goes through customs, customs officials see if there is a need to add an additional cost or import tax, based on the information on the commercial or pro-forma invoice, which will need to print from our site and accompany your package. You obviously want your package to arrive as soon as possible. To do this, you must ensure that the address and the shipment details are entered correctly. Place the labels and the destination address on top of the carton. Please avoid putting them on closures, corners, or tape.',
    'europeHeading3': 'DEADLINES AND FORMALITIES FOR SENDING A PARCEL IN EURPOE',
    'europePara7': 'Parcel shipments to European countries take on average between one and two days, depending on the destination country.',
    'europePara8': 'Regarding customs formalities, Our Customer Service is at your service to answer your questions.',

    'Envoi de Document et Colis en Albanie': 'Ship a document or parcel to Albania',

    'Envoi de Document et Colis en Allemagne': 'Ship a document or parcel to Germany',

    'Envoi de Document et Colis en Andorre': 'Ship a document or parcel to Andorra',

    'Envoi de Document et Colis en Angleterre': 'Ship a document or parcel to England',

    'Envoi de Document et Colis en Autriche': 'Ship a document or parcel to Autriche',

    'Envoi de Document et Colis en Belgique': 'Ship a document or parcel to Belgium',

    'Envoi de Document et Colis en Bosnie Herzégovine': 'Ship a document or parcel to Bosnia Herzegovina',

    'Envoi de Document et Colis en Bulgarie': 'Ship a document or parcel to Bulgaria',

    'Envoi de Document et Colis en Bélarus': 'Ship a document or parcel to Belarus',

    'Envoi de Document et Colis Ceuta': 'Ship a document or parcel to Ceuta',

    'Envoi de Document et Colis en Corse': 'Ship a document or parcel to Corsica',

    'Envoi de Document et Colis en Croatie': 'Ship a document or parcel to Croatia',

    'Envoi de Document et Colis au Danemark': 'Ship a document or parcel to Denmark',

    'Envoi de Document et Colis en Espagne': 'Ship a document or parcel to Spain',

    'Envoi de Document et Colis en Finlande': 'Ship a document or parcel to Finland',

    'Envoi de Document et Colis en France': 'Ship a document or parcel to France',

    'Envoi de Document et Colis en Grèce': 'Ship a document or parcel to Greece',

    'Envoi de Document et Colis en Hongrie': 'Ship a document or parcel to Hungary',

    'Envoi de Document et Colis aux Îles Baléares': 'Ship a document or parcel to Balearic Islands',

    'Envoi de Document et Colis aux Îles Canaries': 'Ship a document or parcel to Canary Islands',

    'Envoi de Document et Colis en Irlande': 'Ship a document or parcel to Ireland',

    'Envoi de Document et Colis en Islande': 'Ship a document or parcel to Iceland',

    'Envoi de Document et Colis en Italie': 'Ship a document or parcel to Italy',

    'Envoi de Document et Colis en Sicile': 'Ship a document or parcel to Sicily',

    'Envoi de Document et Colis en Lettonie': 'Ship a document or parcel to Latvia',

    'Envoi de Document et Colis en Lituanie': 'Ship a document or parcel to Lithuania',

    'Envoi de Document et Colis au Luxembourg': 'Ship a document or parcel to Luxembourg',

    'Envoi de Document et Colis en Macédoine': 'Ship a document or parcel to Macedonia',

    'Envoi de Document et Colis à Madère': 'Ship a document or parcel to Madeira',

    'Envoi de Document et Colis à Malte': 'Ship a document or parcel to Malta',

    'Envoi de Document et Colis à Melilla': 'Ship a document or parcel to Melilla',

    'Envoi de Document et Colis en Moldavie': 'Ship a document or parcel to Moldova',

    'Envoi de Document et Colis à Monaco': 'Ship a document or parcel to Monaco',

    'Envoi de Document et Colis en Norvège': 'Ship a document or parcel to Norway',

    'Envoi de Document et Colis au Pays bas': 'Ship a document or parcel to Netherlands',

    'Envoi de Document et Colis en Pologne': 'Ship a document or parcel to Poland',

    'Envoi de Document et Colis au Portugal': 'Ship a document or parcel to Portugal',

    'Envoi de Document et Colis en Roumanie': 'Ship a document or parcel to Romania',

    'Envoi de Document et Colis en Russie': 'Ship a document or parcel to Russia',

    'Envoi de Document et Colis en République Tchèque': 'EShip a document or parcel to Czech republic',

    'Envoi de Document et Colis en Sardaigne': 'Ship a document or parcel to Sardinia',

    'Envoi de Document et Colis en Serbie': 'Ship a document or parcel to Serbia',

    'Envoi de Document et Colis en Slovaquie': 'Ship a document or parcel to Slovakia',

    'Envoi de Document et Colis en Slovénie': 'Ship a document or parcel to Slovenia',

    'Envoi de Document et Colis en Suisse': 'Ship a document or parcel to Swiss',

    'Envoi de Document et Colis en Ukraine': 'Ship a document or parcel to Ukraine',
    '»': '',
    '«': '',
}

const transFR = {
    'europeHeading1': 'COMMENT ENVOYER UN COLIS EN EUROPE RAPIDEMENT ET PAS CHER AVEC EASYSHIP',
    'europePara1': 'Pour effectuer un envoi de colis en Europe sur Easyship.ma, insérez le poids de votre colis ainsi que ses dimensions dans le moteur de recherche ci-dessus. Accédez à la page où se trouvent les divers services que nous vous offrons, à prix très compétitifs, simplement en cliquant sur “Comparez les Prix”. Comparez ensuite les offres de nos partenaires et leurs tarifs, puis faites votre choix. En fonction du transporteur qui se chargera de votre envoi, il vous sera demandé d’imprimer le bordereau d’envoi.',
    'europeSubPara1': 'La section',
    '»': '»',
    '«': '«',
    'europeTransporteurs': 'Transporteurs',
    'europeSubPara3': 'livraison de votre expéditions en Europe',
    'europeSubPara2': 'permet de connaitre les différents\n' +
    '                            transporteurs travaillant en\n' +
        '                            collaboration avec nous, notamment DHL, UPS, FedEx… Easyship assure aussi bien la ',
    'europeSubPara4': ', en toute\n' +
    '                            transparence puisqu\'à tout\n' +
    '                            moment, vous pouvez suivre votre colis en cliquant sur « Suivi du Colis ».',
    'europePara2': 'La section «<1> Transporteurs </1>» permet de connaitre les différents\n' +
    '                            transporteurs\n' +
        '                            travaillant en collaboration avec nous, notamment DHL, UPS, FedEx… Easyship\n' +
    '                            assure aussi bien la <1>livraison de votre expéditions en Europe</1>, en toute\n' +
    '                            transparence\n' +
    '                            puisqu\'à tout moment, vous pouvez suivre votre colis en cliquant sur « Suivi du Colis ».',
    'europeHeading2': 'CONSEILS POUR ENVOYER VOTRE COLIS EN EUROPE',
    'europePara3': 'Pour tous les envois en Europe depuis les pays hors UE, il faut remplir des formulaires simples. Ne vous inquiétez pas, nous nous chargeons de vous informer des démarches à suivre et de la documentation que vous devrez fournir en fonction des pays. Souvenez-vous qu’il est nécessaire d’inclure dans les données demandées, le numéro de téléphone de la personne qui reçoit le colis ; cela aide à ce que le colis soit livré dès le premier passage du transporteur. Dans certains pays, nous appelons cette même personne si l’adresse est incomplète. Ceci s’applique pour la France et le Portugal par exemple.',
    'europePara4': 'Vous devez également veiller à bien calculer le poids et dimensions de votre colis afin de pouvoir choisir le type de services adapté à votre colis parmi les offres de nos transporteurs.\n' +
    '\n',
    'europePara5': 'N’oubliez pas de mettre votre article dans un carton et de le protéger correctement lorsque vous réalisez vos envois en Europe (choisissez un emballage adéquat et du matériel qui amortit les impacts, comme des films à bulles, du polystyrène, etc). Assurez-vous que le colis soit bien fermé. S’il y a plus d’un objet dans la caisse, emballez chacun des articles séparément, vous éviterez ainsi que les articles s’abîment entre eux. Les articles les plus délicats et les plus lourds auront besoin d’une bonne protection au fond de la caisse, les latéraux, et la partie supérieure du carton.',
    'europePara6': 'Vous devrez prendre en compte le fait que les envois vers les pays Européens passent par la douane. Quand votre colis passe le contrôle de la douane, les douaniers voient s’il est nécessaire d’ajouter un coût supplémentaire ou une taxe d’importation, en fonction de l’information qui figure sur la facture commerciale ou pro-forma, qui devra s’imprimer depuis notre site et accompagner votre colis. Vous souhaitez bien évidemment que votre colis arrive dans les plus brefs délais. Pour cela, vous devez faire attention à ce que l’adresse et les données de l’envoi soient indiquées correctement. Placez les étiquettes et l’adresse de destination sur le dessus du carton. Evitez de les mettre sur les fermetures, les coins, ou le ruban adhésif.\n' +
    '\n',
    'europeHeading3': 'DELAIS ET FORMALITES POUR ENVOYER UN COLIS EN EURPOE',
    'europePara7': 'Les envois de colis vers les pays européens prennent en moyenne entre un et deux jours tout dépend du pays destinataire.\n' +
    '\n',
    'europePara8': 'Concernant les formalités douanières, Notre Service Client est à votre service pour répondre à vos questions.\n' +
    '\n',


    'Envoi de Document et Colis en Albanie': 'Envoi de Document et Colis en Albanie',

    'Envoi de Document et Colis en Allemagne': 'Envoi de Document et Colis en Allemagne',

    'Envoi de Document et Colis en Andorre': 'Envoi de Document et Colis en Andorre',

    'Envoi de Document et Colis en Angleterre': 'Envoi de Document et Colis en Angleterre',

    'Envoi de Document et Colis en Autriche': 'Envoi de Document et Colis en Autriche',

    'Envoi de Document et Colis en Belgique': 'Envoi de Document et Colis en Belgique',

    'Envoi de Document et Colis en Bosnie Herzégovine': 'Envoi de Document et Colis en Bosnie Herzégovine',

    'Envoi de Document et Colis en Bulgarie': 'Envoi de Document et Colis en Bulgarie',

    'Envoi de Document et Colis en Bélarus': 'Envoi de Document et Colis en Bélarus',

    'Envoi de Document et Colis Ceuta': 'Envoi de Document et Colis Ceuta',

    'Envoi de Document et Colis en Corse': 'Envoi de Document et Colis en Corse',

    'Envoi de Document et Colis en Croatie': 'Envoi de Document et Colis en Croatie',

    'Envoi de Document et Colis au Danemark': 'Envoi de Document et Colis au Danemark',

    'Envoi de Document et Colis en Espagne': 'Envoi de Document et Colis en Espagne',

    'Envoi de Document et Colis en Finlande': 'Envoi de Document et Colis en Finlande',

    'Envoi de Document et Colis en France': 'Envoi de Document et Colis en France',

    'Envoi de Document et Colis en Grèce': 'Envoi de Document et Colis en Grèce',

    'Envoi de Document et Colis en Hongrie': 'Envoi de Document et Colis en Hongrie',

    'Envoi de Document et Colis aux Îles Baléares': 'Envoi de Document et Colis aux Îles Baléares',

    'Envoi de Document et Colis aux Îles Canaries': 'Envoi de Document et Colis aux Îles Canaries',

    'Envoi de Document et Colis en Irlande': 'Envoi de Document et Colis en Irlande',

    'Envoi de Document et Colis en Islande': 'Envoi de Document et Colis en Islande',

    'Envoi de Document et Colis en Italie': 'Envoi de Document et Colis en Italie',

    'Envoi de Document et Colis en Sicile': 'Envoi de Document et Colis en Sicile',

    'Envoi de Document et Colis en Lettonie': 'Envoi de Document et Colis en Lettonie',

    'Envoi de Document et Colis en Lituanie': 'Envoi de Document et Colis en Lituanie',

    'Envoi de Document et Colis au Luxembourg': 'Envoi de Document et Colis au Luxembourg',

    'Envoi de Document et Colis en Macédoine': 'Envoi de Document et Colis en Macédoine',

    'Envoi de Document et Colis à Madère': 'Envoi de Document et Colis à Madère',

    'Envoi de Document et Colis à Malte': 'Envoi de Document et Colis à Malte',

    'Envoi de Document et Colis à Melilla': 'Envoi de Document et Colis à Melilla',

    'Envoi de Document et Colis en Moldavie': 'Envoi de Document et Colis en Moldavie',

    'Envoi de Document et Colis à Monaco': 'Envoi de Document et Colis à Monaco',

    'Envoi de Document et Colis en Norvège': 'Envoi de Document et Colis en Norvège',

    'Envoi de Document et Colis au Pays bas': 'Envoi de Document et Colis au Pays bas',

    'Envoi de Document et Colis en Pologne': 'Envoi de Document et Colis en Pologne',

    'Envoi de Document et Colis au Portugal': 'Envoi de Document et Colis au Portugal',

    'Envoi de Document et Colis en Roumanie': 'Envoi de Document et Colis en Roumanie',

    'Envoi de Document et Colis en Russie': 'Envoi de Document et Colis en Russie',

    'Envoi de Document et Colis en République Tchèque': 'Envoi de Document et Colis en République Tchèque',

    'Envoi de Document et Colis en Sardaigne': 'Envoi de Document et Colis en Sardaigne',

    'Envoi de Document et Colis en Serbie': 'Envoi de Document et Colis en Serbie',

    'Envoi de Document et Colis en Slovaquie': 'Envoi de Document et Colis en Slovaquie',

    'Envoi de Document et Colis en Slovénie': 'Envoi de Document et Colis en Slovénie',

    'Envoi de Document et Colis en Suisse': 'Envoi de Document et Colis en Suisse',

    'Envoi de Document et Colis en Ukraine': 'Envoi de Document et Colis en Ukraine',
    'parcelTracking': '',


}

export default {
    en: transEN,
    fr: transFR
}
