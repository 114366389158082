const transEN = {

    'UpsHeading1': 'Send a package with UPS: offers, express shipping',
    'UpsHeading2': 'UPS manages the flow of goods, capital and information in more than 215 countries. Its efficiency and productivity have enabled it to become the number one in express parcel transport in the world.',
    'UpsPara1': 'Today, this internationally renowned <1>courier company</1> is doing very well,\n' +
    '                            earning billions of dollars in gross profit each year.',
    'UpsPara2': 'All this information allows us to affirm that by choosing UPS you benefit from a wide choice\n' +
    '                            of guaranteed options with a fixed deadline, and this, thanks to <1>one of the largest\n' +
    '                            transport networks in the world</1>.',
    'UpsHeading3':'Sending packages with UPS and UPS Express',
    'UpsPara3': 'express delivery service in D + 1 always door-to-door',
    'UpsPara4': 'delivery between 1 and 5 days (depending on the destination)',
    'UpsPara5': 'maximum 300 kg per shipment'
}

const transFR = {
    'UpsHeading1': 'Envoyer un colis avec UPS : offres, envoi express',
    'UpsHeading2': 'UPS gère des flux de marchandises, de capitaux et d’information dans plus de 215 pays. Son\n' +
    '                            efficacité et\n' +
    '                            sa productivité lui ont permis de devenir le numéro un du transport express de colis dans le\n' +
    '                            monde.',
    'UpsPara1': 'Aujourd’hui, cette <1>société de messagerie</1> de renommée internationale se porte à\n' +
    '                            merveille et\n' +
    '                            engrange\n' +
    '                            chaque année plusieurs milliards de dollars de marge brute.',
    'UpsPara2': 'Toutes ces informations nous permettent d’affirmer qu’en choisissant UPS vous bénéficiez\n' +
    '                            d’un\n' +
    '                            large\n' +
    '                            choix d’options garanties à délai déterminé, et cela, grâce à l’<1>un des plus grands\n' +
    '                                réseaux de\n' +
    '                                transport du\n' +
    '                                monde.</1>',
    'UpsHeading3':'L\'envoi de colis avec UPS et UPS Express',
    'UpsPara3': 'service express de livraison en J+1 toujours en porte-à-porte',
    'UpsPara4': 'livraison entre 1 et 5 jours (suivant la destination)',
    'UpsPara5': 'maximum 300 kg par expédition'
}

export default {
    en: transEN,
    fr: transFR
}
